import { gql } from '@apollo/client';

import { ORGANIZATION_FRAGMENT } from 'shared/graphql';

export const ORGANIZATION_INFO_FRAGMENT = gql`
  fragment OrganizationInfo on Organization {
    id
    name
    createdBy {
      id
    }
    managers {
      items {
        id
        user {
          id
        }
      }
    }
  }
`;

const AD_ITEM_FRAGMENT = gql`
  fragment AdItem on AdvertisingCampaignAd {
    id
    name
    primaryText
    callToActionText
    callToActionLink
    totalSpend
    todaySpend
    dailyBudget
    dailyBudgetEnabled
    totalBudget
    totalBudgetEnabled
    createdAt

    # Used to filter on the client side because of a issue with 8base.
    # https://8base-dev.atlassian.net/browse/JEB-1431?focusedCommentId=42554
    audiences {
      items {
        id
        industry
        affiliation
        graduatingYear
        zipCode
        city
        state
        hobbies
        clubs
      }
    }

    sampaign {
      id
      name
      advertising {
        id
        organization {
          ...OrganizationItem
        }
        supporter {
          isSupporter
        }
      }
    }

    image {
      downloadUrl
      id
    }

    userViews {
      count
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

export const CAMPAIGN_ADS_LIST_QUERY = gql`
  query CampaignAdsList($filter: AdvertisingCampaignAdFilter) {
    advertisingCampaignAdsList(filter: $filter) {
      items {
        ...AdItem
      }
    }
  }

  ${AD_ITEM_FRAGMENT}
`;

export const UPDATE_AD_MUTATION = gql`
  mutation UpdateCampaignAd($data: AdvertisingCampaignAdUpdateInput!, $id: ID) {
    advertisingCampaignAdUpdate(data: $data, filter: { id: $id }) {
      id
    }
  }
`;

export const AD_VIEW_CREATE_MUTATION = gql`
  mutation AdViewCreate($user: ID!, $ad: ID!, $school: ID!) {
    view: adViewCreate(
      data: {
        user: { connect: { id: $user } }
        ad: { connect: { id: $ad } }
        school: { connect: { id: $school } }
      }
    ) {
      id
    }
  }
`;

export const AD_CLICK_CREATE_MUTATION = gql`
  mutation AdClickCreate($user: ID!, $ad: ID!, $school: ID!) {
    view: adClickCreate(
      data: {
        user: { connect: { id: $user } }
        ad: { connect: { id: $ad } }
        school: { connect: { id: $school } }
      }
    ) {
      id
    }
  }
`;

export const AD_LIKES_QUERY = gql`
  query AdLikes($adId: ID!) {
    advertisingCampaignAd(id: $adId) {
      userLikes {
        count
        items {
          id
        }
      }
    }
  }
`;
