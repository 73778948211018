import { useMemo } from 'react';

import { createFilterBuilder, ParseDateValue } from '@jebel/utils';

import { useMembersCountByAffiliationQuery, UserFilter } from 'shared/graphql';

import { useSchoolConfiguration } from 'shared/hooks';
import { USER_AFFILIATIONS } from '@jebel/constants';

interface Params {
  /**
   * Start date for the filter.
   */
  startDate?: ParseDateValue;

  /**
   * End date for the filter.
   */
  endDate?: ParseDateValue;
}

export interface MembersCountByAffiliation {
  affiliation: string;
  count: number;
}

export function useMembersCountByAffiliation(params: Params = {}) {
  const { data: school } = useSchoolConfiguration();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>();

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1675
      filter.and({ schoolMemberships: { some: { school: { id: { equals: school.id } } } } });
    }

    if (params.startDate) {
      filter.and({ createdAt: { gte: params.startDate } });
    }

    if (params.endDate) {
      filter.and({ createdAt: { lte: params.endDate } });
    }

    return filter.build();
  }, [params, school]);

  const { data: response, loading } = useMembersCountByAffiliationQuery({
    skip: !school,
    variables: { filter },
  });

  const alumni = response?.alumni?.count ?? 0;
  const student = response?.student?.count ?? 0;
  const staff = response?.staff?.count ?? 0;
  const parent = response?.parent?.count ?? 0;
  const other = response?.other?.count ?? 0;

  const data: MembersCountByAffiliation[] = [
    { affiliation: USER_AFFILIATIONS.alumni, count: alumni },
    { affiliation: USER_AFFILIATIONS.student, count: student },
    { affiliation: USER_AFFILIATIONS.staff, count: staff },
    { affiliation: USER_AFFILIATIONS.parent, count: parent },
    { affiliation: USER_AFFILIATIONS.other, count: other },
  ];

  return {
    data,
    loading,
  };
}
