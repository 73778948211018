import { css } from '@emotion/react';

import { HOME_FEED_ITEM_TYPES, SelectOption } from '@jebel/constants';

import { SELECT_OPTION_ALL } from 'shared/constants';
import { HeadlinesType } from 'shared/features/spreadsheet';
import { ContentReportSort } from 'shared/graphql';

export const REPORT_TYPE = {
  contentEngagementReport: 'Content Engagement Report',
  revenueReport: 'Revenue Report',
  trendingSearchesReport: 'Trending Searches Report',
};

export const SORT_TYPES = {
  noSort: 'noSort',
  comments: 'comments',
  reactions: 'reactions',
  total: 'total',
  postDate: 'postDate',
};

export enum ContentReportHeaders {
  id = 'id',
  text = 'Post',
  category = 'Category',
  postDate = 'Post Date',
  author = 'Author',
  isSupporter = 'Supporter?',
  likesCount = 'Reactions',
  commentsCount = 'Comments',
  reactionsCount = 'Total',
}

export enum RevenueReportHeaders {
  id = 'id',
  advertiser = 'Advertiser',
  revenueGenerated = 'Revenue Generated',
  sponsorsRequests = 'Sponsors Requests',
}

export enum TrendingSearchesReportHeaders {
  wordPhrase = 'Word/Phrase',
  minimumSearchCount = 'Minimum Search Count',
}
export const ContentReportsHeadlines: HeadlinesType<ContentReportSort> = [
  {
    id: 'text',
    name: ContentReportHeaders.text,
    label: 'POST',

    sortPath: order => ({ text: order }),

    css: () =>
      css`
        width: 17.84%;
      `,
  },
  {
    name: ContentReportHeaders.category,
    label: 'CATEGORY',
    id: 'category',
    sortPath: order => ({
      category: order,
    }),
    css: () =>
      css`
        width: 11.03%;
      `,
  },
  {
    name: ContentReportHeaders.postDate,
    label: 'POST DATE',
    id: 'postDate',
    sortPath: order => ({
      createdAt: order,
    }),
    css: () =>
      css`
        width: 14.8%;
      `,
  },
  {
    id: 'author',
    name: ContentReportHeaders.author,
    label: 'AUTHOR',
    sortPath: order => ({ authorName: order }),
    css: () =>
      css`
        width: 13.3%;
      `,
  },
  {
    id: 'isSupporter',
    name: ContentReportHeaders.isSupporter,
    label: 'SUPPORTER?',
    sortPath: order => ({ isSupporter: order }),
    css: () =>
      css`
        width: 11.36%;
      `,
  },
  {
    id: 'likesCount',
    name: ContentReportHeaders.likesCount,
    label: 'REACTIONS',
    css: () =>
      css`
        width: 10.41%;
      `,
  },
  {
    id: 'commentsCount',
    name: ContentReportHeaders.commentsCount,
    label: 'COMMENTS',
    css: () =>
      css`
        width: 9.7%;
      `,
  },
  {
    id: 'reactionsCount',
    name: ContentReportHeaders.reactionsCount,
    label: 'TOTAL',
    css: () =>
      css`
        width: 7.67%;
      `,
  },
];

export const POST_CATEGORIES: SelectOption[] = [
  { label: 'All', value: SELECT_OPTION_ALL },
  { label: 'School Posts', value: HOME_FEED_ITEM_TYPES.SCHOOL },
  { label: 'User Posts', value: HOME_FEED_ITEM_TYPES.HOME },
  { label: 'Events', value: HOME_FEED_ITEM_TYPES.EVENT },
  { label: 'Legacy Posts', value: HOME_FEED_ITEM_TYPES.LEGACY },
  { label: 'Group Posts', value: HOME_FEED_ITEM_TYPES.GROUP },
];

export const REVENUE_CATEGORY = {
  all: 'All',
  supporterFees: 'Supporter Fees',
  targetedAds: 'Targeted Ads',
  businessSearch: 'Business Search',
  offers: 'Offers',
  eventSponsorship: 'Event Sponsorship',
};

export const RevenueReportsSpreadsheetHeadlines: HeadlinesType = [
  {
    name: RevenueReportHeaders.advertiser,
    label: 'ADVERTISER',
    id: 'advertiser',
    withoutSort: true,
  },
  {
    name: RevenueReportHeaders.revenueGenerated,
    label: 'REVENUE GENERATED',
    id: 'revenue',
    withoutSort: true,
  },
];

export const RevenueReportsSpreadsheetHeadlinesEvent: HeadlinesType = [
  {
    name: RevenueReportHeaders.advertiser,
    label: 'ADVERTISER',
    id: 'advertiser',
    withoutSort: true,
  },
  {
    name: RevenueReportHeaders.sponsorsRequests,
    label: 'Sponsor Requests',
    id: 'revenue',
    withoutSort: true,
  },
];

export const TrendingSearchesReportsSpreadsheetHeadlines: HeadlinesType = [
  {
    name: 'word',
    label: 'WORD/PHRASE',
    id: 'word',
    sortPath: order => ({
      wordOrPhrase: order,
    }),
  },
  {
    name: 'count',
    label: 'MINIMUM SEARCH COUNT',
    id: 'count',
    sortPath: order => ({
      searchesCount: order,
    }),
  },
];
