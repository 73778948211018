import { css } from '@emotion/react';

import { GROUP_STATUSES } from '@jebel/constants';

import { HeadlinesType, SpreadsheetFiltersType } from 'shared/features/spreadsheet';
import { GroupFilter, GroupSort, SortOrder } from 'shared/graphql';

export enum GroupSpreadSheetHeaders {
  title = 'Title',
  createdBy = 'Created By',
  createdOn = 'Created On',
  post = 'Posts',
  member = 'Members',
  status = 'Status',
}
export const GroupsSpreadsheetHeader: HeadlinesType<GroupSort> = [
  {
    name: 'title',
    label: 'TITLE',
    id: 'title',
    sortPath: (order: SortOrder) => ({ title: order }),
    css: () =>
      css`
        width: 22.9%;
      `,
  },
  {
    name: 'createdBy',
    label: 'Created By',
    id: 'createdBy.id',
    sortPath: (order: SortOrder) => [{ createdBy: { fullName: order } }],
    css: () =>
      css`
        width: 18.43%;
      `,
  },
  {
    name: 'createdAt',
    label: 'Created On',
    id: 'createdAt',
    sortPath: (order: SortOrder) => ({ createdAt: order }),
    css: () =>
      css`
        width: 15.25%;
      `,
  },
  {
    name: 'posts',
    label: 'Posts',
    id: 'posts',
    sortPath: (order: SortOrder) => ({ postsCount: order }),
    css: () =>
      css`
        width: 8.94%;
      `,
  },
  // TODO: Set the correct number of posts/members into database in whole app (JEB-1030)
  {
    name: 'members',
    label: 'Members',
    id: 'members',
    sortPath: (order: SortOrder) => ({ membersCount: order }),
    css: () =>
      css`
        width: 11.73%;
      `,
    isNumeric: true,
  },
  {
    name: 'status',
    label: 'STATUS',
    id: 'status',
    sortPath: (order: SortOrder) => ({ status: order }),
    css: () =>
      css`
        width: 12.96%;
      `,
  },
];

export const GroupMembersSpreadsheetHeader: HeadlinesType = [
  {
    name: 'name',
    label: 'NAME',
    id: 'name',
    sortPath: (order: SortOrder) => ({ firstName: order }),
  },
  {
    name: 'type',
    label: 'TYPE',
    id: 'type',
    sortPath: (order: SortOrder) => ({ affiliation: order }),
  },
  {
    name: 'posts',
    label: 'Posts',
    id: 'posts',
    withoutSort: true,
  },
];

export const GROUP_STATUS_OPTIONS = [
  {
    label: 'Pending Approval',
    value: GROUP_STATUSES.pending,
  },
  {
    label: 'Inactive',
    value: GROUP_STATUSES.inactive,
  },
  {
    label: 'Active',
    value: GROUP_STATUSES.active,
  },
  {
    label: 'Rejected',
    value: GROUP_STATUSES.rejected,
  },
] as const;

export const GroupSpreadsheetFilters: SpreadsheetFiltersType<GroupFilter> = [
  {
    type: 'select',
    variants: GROUP_STATUS_OPTIONS.map(option => ({ value: option.value, text: option.label })),
    label: 'Status',
    name: 'status',
    placeholder: 'Group status',
    size: 'full',

    filterPath(value: string) {
      if (!value) {
        return {};
      }

      return { status: { equals: value } };
    },
  },
];

export const canActivateGroup = (status: string) =>
  [GROUP_STATUSES.pending, GROUP_STATUSES.inactive].includes(status);

export const canDeactivateGroup = (status: string) =>
  [GROUP_STATUSES.pending, GROUP_STATUSES.active].includes(status);
