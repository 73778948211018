import { createContext, PropsWithChildren, useContext, useEffect, useMemo } from 'react';
import { useTheme } from '@emotion/react';
import { Helmet } from 'react-helmet';

import { processFilestackUrl } from '@jebel/utils';

import { LOCAL_STORAGE_SELECTED_SCHOOL } from 'shared/constants';
import { useCurrentUserSchools, useLocalStorageState, useQueryParams } from 'shared/hooks';
import { recordDebug, recordError, recordEvent } from 'shared/utils/record';
import {
  CurrentSchoolConfigurationFragment,
  useCurrentSchoolConfigurationQuery,
} from 'shared/graphql';

const SERVER_URL = process.env.REACT_APP_ENDPOINT;

export interface SchoolProvidedContext {
  /**
   * The currently selected school.
   * If no school is selected, this will be `null`.
   * If a school is selected, this will be an object with the `id` of the selected school.
   */
  selectedSchool: CurrentSchoolConfigurationFragment | null;

  /**
   * Select a school by `id`.
   * @param id The `id` of the school to select.
   */
  selectSchool(id: string): void;

  /**
   * Whether the school is currently loading.
   */
  loading: boolean;
}

const SchoolContext = createContext<SchoolProvidedContext>({
  loading: true,
  selectedSchool: {},
  selectSchool: () => {},
});

interface Params {
  school?: string;
}

function useCreateSchoolContext(): SchoolProvidedContext {
  const [params] = useQueryParams<Params>();

  const [selected, setSelected] = useLocalStorageState<CurrentSchoolConfigurationFragment>(
    LOCAL_STORAGE_SELECTED_SCHOOL,
  );

  const { data: schools, loading: schoolsLoading } = useCurrentUserSchools();

  const { data: response, loading: selectedSchoolLoading } = useCurrentSchoolConfigurationQuery({
    skip: !selected,
    variables: { id: selected?.id as string },

    onCompleted({ school }) {
      if (!school) {
        return;
      }

      recordDebug('Selecting school loaded from remote');
      // Update the local storage with the latest data.
      setSelected(school);
    },
  });

  const selectedSchool = response?.school ?? selected ?? null;
  const loading = schoolsLoading || selectedSchoolLoading;

  useEffect(() => {
    if (loading || !schools.length) {
      return;
    }

    const selectedExists = schools.some(school => school.id === selected?.id);

    if (selected?.id && selectedExists) {
      // The selected school is in the list of schools.
      return;
    }

    if (params.school) {
      // The selected school is in the URL.
      selectSchool(params.school);
      return;
    }

    // The selected school is not in the list of schools.
    // Select the first school in the list by default.
    setSelected(schools[0]);
  }, [params, schools, loading]);

  const selectSchool = (id: string): void => {
    const school = schools.find(school => school.id === id);

    if (!school) {
      const firstSchool = schools[0];

      if (!selected && firstSchool?.id) {
        recordDebug('No school selected, selecting the first school in the list.');
        // Prevent to leave the user without a selected school.
        return selectSchool(firstSchool.id);
      }

      return recordError(`School "${id}" not found in the list of schools.`);
    }

    recordEvent('Select School', { 'School ID': id, 'School Name': school.name! });
    setSelected(school);
  };

  return {
    selectedSchool,
    selectSchool,

    loading,
  };
}

const FAVICON_SIZE = 32;
const TOUCH_ICON_SIZE = 192;

export function SchoolProvider(props: PropsWithChildren<unknown>) {
  const theme = useTheme();
  const context = useCreateSchoolContext();

  const school = useMemo(() => context.selectedSchool, [context]);

  const faviconURL = useMemo(() => {
    const source = school?.images?.browserLogo?.downloadUrl;

    if (!source) {
      return '/favicon.ico';
    }

    return processFilestackUrl(source, {
      resize: { width: FAVICON_SIZE, height: FAVICON_SIZE },
    });
  }, [school]);

  const touchIconURL = useMemo(() => {
    const source = school?.images?.browserLogo?.downloadUrl;

    if (!source) {
      return '/favicon.ico';
    }

    return processFilestackUrl(source, {
      resize: { width: TOUCH_ICON_SIZE, height: TOUCH_ICON_SIZE },
    });
  }, [school]);

  const manifestURL = useMemo(() => {
    if (!school) {
      // Return the fallback manifest URL.
      return '/manifest.json';
    }

    // Return the school-specific manifest URL.
    // https://github.com/jebelapp/jebel/issues/1658
    return `${SERVER_URL}/webhook/school/${school.id}/manifest`;
  }, [school]);

  return (
    <SchoolContext.Provider value={context}>
      <Helmet>
        <title>{school?.fullName ?? school?.name ?? 'Jebel'}</title>

        <meta name="theme-color" content={theme.palette.primary.main} />

        <link rel="manifest" href={manifestURL} />

        <link rel="icon" href={faviconURL} />
        <link rel="apple-touch-icon" href={touchIconURL} />
      </Helmet>

      {props.children}
    </SchoolContext.Provider>
  );
}

/**
 * Custom hook to access the `SchoolContext`.
 *
 * This hook provides the current value of the `SchoolContext`. It must be used
 * within a component that is a descendant of a {@linkcode SchoolProvider}. If used outside
 * of a {@linkcode SchoolProvider}, it will throw an error.
 *
 * @throws {Error} If the hook is used outside of a {@linkcode SchoolProvider}.
 * @returns The current context value of the `SchoolContext`.
 */
export function useSchoolContext() {
  const context = useContext(SchoolContext);

  if (!context) {
    throw new Error(`"useSchoolContext" must be used within a "SchoolProvider"`);
  }

  return context;
}
