import { gql } from '@apollo/client';

import { ORGANIZATION_FRAGMENT, ORGANIZATION_PROFILE_FRAGMENT } from 'shared/graphql';

export const ORGANIZATION_CREATE_MUTATION = gql`
  mutation OrganizationCreate($data: OrganizationCreateInput!) {
    claimOrganization(data: $data) {
      id
    }
  }
`;

export const ORGANIZATION_UPDATE_MUTATION = gql`
  mutation OrganizationUpdate($data: OrganizationUpdateInput!) {
    organizationUpdate(data: $data) {
      ...OrganizationItem
    }
  }
`;

export const ORGANIZATION_UPDATE_BY_FILTER_MUTATION = gql`
  mutation ORGANIZATION_UPDATE_BY_FILTER_MUTATION(
    $data: OrganizationUpdateByFilterInput!
    $filter: OrganizationFilter
  ) {
    organizationUpdateByFilter(data: $data, filter: $filter) {
      items {
        id
        name
        homeFeedViews
      }
    }
  }
`;

export const ORGANIZATION_QUERY = gql`
  query Organization($id: ID!) {
    organization(id: $id) {
      ...OrganizationItem
    }
  }

  ${ORGANIZATION_FRAGMENT}
`;

export const ORGANIZATION_PROFILE_QUERY = gql`
  query OrganizationProfile($id: ID!) {
    organization(id: $id) {
      ...OrganizationProfile
    }
  }

  ${ORGANIZATION_PROFILE_FRAGMENT}
`;

export const ORGANIZATIONS_LIST_QUERY = gql`
  query OrganizationsList(
    $filter: OrganizationFilter
    $sort: [OrganizationSort!]
    $first: Int
    $skip: Int
  ) {
    organizations: organizationsList(filter: $filter, sort: $sort, first: $first, skip: $skip) {
      count
      items {
        ...OrganizationItem
      }
    }
  }
  ${ORGANIZATION_FRAGMENT}
`;

export const BUSINESS_REGISTRY_LIST_QUERY = gql`
  query BusinessRegistryList(
    $school: ID!
    $filter: OrganizationLocationFilter
    $first: Int
    $skip: Int
    $sort: [OrganizationLocationSort!]
  ) {
    locations: organizationLocationsList(first: $first, skip: $skip, sort: $sort, filter: $filter) {
      count
      items {
        id
        name: locationName
        createdAt

        address {
          items {
            state
            city
            street1
            zip
          }
        }

        organization {
          id
          name
          industry
          createdAt

          logo {
            fileId
            downloadUrl
          }

          managers {
            items {
              status

              user {
                id
                firstName
                lastName
                email
              }
            }
          }

          currentSchoolSupport: schoolSupporter(filter: { school: { id: { equals: $school } } }) {
            count
          }

          createdBy {
            id
            firstName
            lastName
            email
          }
        }
      }
    }
  }
`;

export const BUSINESSES_COUNT_QUERY = gql`
  query BusinessCount(
    $filter: OrganizationFilter
    $startPointZip: String!
    $radius: String!
    $supporterFilter: OrganizationFilter
  ) {
    businesses: businessFilterByZipRequest(
      filter: $filter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
    supporters: businessFilterByZipRequest(
      filter: $supporterFilter
      startPointZip: $startPointZip
      radius: $radius
    ) {
      count
    }
  }
`;

export const ORGANIZATIONS_COUNT_QUERY = gql`
  query OrganizationsCount($filter: OrganizationFilter) {
    organizationsList(filter: $filter) {
      count
    }
  }
`;

export const ORGANIZATION_CREATE_LIKE_MUTATION = gql`
  mutation OrganizationCreateLike($userId: ID!, $organizationId: ID!) {
    organizationLikeCreate(
      data: {
        user: { connect: { id: $userId } }
        organization: { connect: { id: $organizationId } }
      }
    ) {
      id
      user {
        id
      }
      organization {
        id
      }
    }
  }
`;

export const ORGANIZATION_DELETE_LIKE_MUTATION = gql`
  mutation OrganizationDeleteLike($likeId: ID!) {
    organizationLikeDelete(data: { id: $likeId }) {
      success
    }
  }
`;

export const SCHOOL_SUPPORTER_SEARCH_CREATE_MUTATION = gql`
  mutation SchoolSupporterSearchCreate($userId: ID!, $supporterId: ID!) {
    schoolSupporterSearchCreate(
      data: { user: { connect: { id: $userId } }, supporter: { connect: { id: $supporterId } } }
    ) {
      id
      user {
        id
      }
      supporter {
        id
      }
    }
  }
`;

export const SCHOOL_SUPPORTER_CLICK_CREATE_MUTATION = gql`
  mutation SchoolSupporterClickCreate($userId: ID!, $supporterId: ID!) {
    schoolSupporterClickCreate(
      data: { user: { connect: { id: $userId } }, supporter: { connect: { id: $supporterId } } }
    ) {
      id
      user {
        id
      }
      supporter {
        id
      }
    }
  }
`;
