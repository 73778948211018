import { useMemo } from 'react';

import { USER_AFFILIATIONS, type UserAffiliations, type UserStatuses } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import { useMemberStatsQuery, UserFilter } from 'shared/graphql';
import { SELECT_OPTION_ALL } from 'shared/constants';
import { useSchoolConfiguration } from 'shared/hooks';

export interface MembersStats {
  totalCount: number;
  other: number;
  student: number;
  parent: number;
  alumni: number;
  staff: number;
}

export function useMembersStats(status?: UserStatuses | typeof SELECT_OPTION_ALL) {
  const { data: school } = useSchoolConfiguration();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>();

    if (school) {
      filter.and({
        schoolMemberships: {
          some: { school: { id: { equals: school.id } } },
        },
      });
    }

    if (status && status !== SELECT_OPTION_ALL) {
      filter.and({
        schoolMemberships: {
          some: { status: { equals: status } },
        },
      });
    }

    return filter.build();
  }, [status]);

  const {
    data: response,
    loading,
    refetch,
  } = useMemberStatsQuery({
    skip: !school,
    variables: {
      filter,
    },
  });

  const membersStats = useMemo<MembersStats>(() => {
    const groups = response?.affiliations.groups ?? [];

    const affiliations: Record<UserAffiliations, number> = {
      [USER_AFFILIATIONS.alumni]: 0,
      [USER_AFFILIATIONS.parent]: 0,
      [USER_AFFILIATIONS.student]: 0,
      [USER_AFFILIATIONS.staff]: 0,
      [USER_AFFILIATIONS.other]: 0,
    };

    for (const group of groups) {
      if (!group.affiliation) {
        // Prevents to count users with no affiliation.
        continue;
      }

      const count = group.count ?? 0;

      affiliations[group.affiliation as UserAffiliations] = count;
    }

    const totalCount = response?.affiliations.totalCount ?? 0;

    const metrics: MembersStats = {
      ...affiliations,

      totalCount,
    };

    return metrics;
  }, [response]);

  const refetchStats = async () => {
    await refetch();
  };

  return {
    membersStats,
    loading,
    refetchStats,
  };
}
