import { Box } from '@mui/material';
import { css } from '@emotion/react';

import { DashboardHeader } from './DashboardHeader';
import { DashboardCountStats } from './DashboardCountStats';
import { DashboardMembersCharts } from '../DashboardMembersCharts';
import { DashboardAdvertisingCharts } from '../DashboardAdvertisingCharts';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

export function DashboardLayout() {
  return (
    <Box css={containerCSS}>
      <DashboardHeader />
      <DashboardCountStats />
      <DashboardMembersCharts />
      <DashboardAdvertisingCharts />
    </Box>
  );
}
