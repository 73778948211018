import { useMemo } from 'react';

import { MinimalUserFragment, SchoolMemberFragment } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { generateUserProfileRoute } from 'shared/routes';

import { AuthorNameLink } from './AuthorNameLink';

interface Props {
  user: MinimalUserFragment | SchoolMemberFragment | undefined | null;

  className?: string;
}

export function UserNameLink(props: Props) {
  const name = formatUserName(props.user);

  const route = useMemo(() => {
    return generateUserProfileRoute(props.user?.id);
  }, [props.user]);

  return (
    <AuthorNameLink className={props.className} name={name} route={route} disabled={!props.user} />
  );
}
