import { Fragment } from 'react';
import styled from '@emotion/styled';

import { SORT } from '@jebel/constants';

import { Button, Typography } from 'shared/components/ui';
import { SpreadsheetStats } from 'shared/components/ui/SpreadsheetStats';
import { SpreadsheetProvider } from 'shared/features/spreadsheet/providers';
import { AdminDiscountFragment } from 'shared/graphql';

import { useDiscountsStats } from '../hooks';
import { DiscountModal } from './DiscountModal';
import { DiscountsSpreadsheet } from './DiscountsSpreadsheet';
import { useModalState } from 'shared/hooks';
import { ReedemDiscountModal } from 'features/discounts/components/ReedemDiscountModal';

const MembersContentLayout = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 15px;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 35px 0px;
`;

export function DiscountsContent() {
  const {
    show: showCreate,
    open: openCreate,
    close: closeCreate,
  } = useModalState<AdminDiscountFragment>();

  const {
    params: discountDetail,
    show: showDetail,
    open: openDetail,
    close: closeDetail,
  } = useModalState<AdminDiscountFragment>();

  const { discountStats, loading: discountStatsLoading } = useDiscountsStats();

  return (
    <Fragment>
      <SpreadsheetProvider
        defaultSortOption={{
          order: SORT.desc,
          headcellId: 'createdAt',
          sort: {
            createdAt: SORT.desc,
          },
        }}
      >
        {showCreate && <DiscountModal isModalOpen={showCreate} onModalClose={closeCreate} />}

        {discountDetail && (
          <ReedemDiscountModal
            isOpen={showDetail}
            discount={discountDetail}
            onClose={closeDetail}
          />
        )}

        <MembersContentLayout>
          <Header>
            <Typography variant="subtitle2" color="primary">
              Discounts
            </Typography>

            <Button size="medium" variant="contained" color="primary" onClick={openCreate}>
              New Discount
            </Button>
          </Header>

          <SpreadsheetStats loading={discountStatsLoading} data={discountStats} />

          <DiscountsSpreadsheet onSelectDiscount={openDetail} />
        </MembersContentLayout>
      </SpreadsheetProvider>
    </Fragment>
  );
}
