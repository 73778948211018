import { useCurrentUser, useSchoolConfiguration } from 'shared/hooks';
import { AdvertisingCampaignAdKeyFilter, useAdClickCreateMutation } from 'shared/graphql';

/**
 * Custom hook to create an ad click.
 *
 * This hook uses the current user and school configuration to execute an ad click mutation.
 * It requires the current user and school configuration to be available.
 */
export function useCreateAdClick() {
  const { user } = useCurrentUser();
  const { data: school } = useSchoolConfiguration();

  const [execute, { loading }] = useAdClickCreateMutation();

  const mutate = async (ad: AdvertisingCampaignAdKeyFilter) => {
    if (!user?.id || !school?.id || !ad.id) {
      return;
    }

    await execute({
      variables: {
        user: user.id,
        // Relate with the school where it was clicked.
        // https://github.com/jebelapp/jebel/issues/1697
        school: school.id,
        ad: ad.id,
      },
    });
  };

  return {
    mutate,
    loading,
  };
}
