import { useTheme } from '@material-ui/core';
import RedeemIcon from '@mui/icons-material/Redeem';

import { formatDecimalNumbers } from 'shared/utils/numbers';
import { useSearchDateFilterContext } from 'shared/features/search';
import { DateRange } from 'shared/hooks';

import { useSpecialOffersCount } from '../../../hooks';
import { DashboardCountStatsItem } from '../DashboardCountStats/DashboardCountStatsItem';

export function DashboardCountSpecialOffersWrapper() {
  const theme = useTheme();

  const { filter: dateFilter } = useSearchDateFilterContext<DateRange>();

  const {
    activeOffersCount,
    redeemedOffersCount,
    loading: specialOffersLoading,
  } = useSpecialOffersCount({
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
  });

  return (
    <DashboardCountStatsItem
      title="Special Offers"
      icon={<RedeemIcon />}
      color={theme.palette.secondary.main}
      loading={specialOffersLoading}
      counters={[
        {
          label: 'Total Active Offers',
          count: formatDecimalNumbers(activeOffersCount),
        },
        {
          label: 'Redeemed',
          count: formatDecimalNumbers(redeemedOffersCount),
        },
      ]}
    />
  );
}
