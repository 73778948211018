import { useCallback, useMemo, useState } from 'react';
import { uniqBy } from 'ramda';

import { SORT } from '@jebel/constants';

import {
  EventFilter,
  EventSearchQuery,
  EventSearchQueryVariables,
  EventSort,
  SortOrder,
  useEventSearchQuery,
} from 'shared/graphql';
import { PAGE_SIZE } from 'shared/constants';
import { isValidZipCode } from 'shared/utils/form';
import { createEventFilter } from 'features/search/utils';
import { recordError } from 'shared/utils/record';
import { useSchoolConfiguration } from './useSchoolConfiguration';
import { SearchContextZipFilter } from 'shared/features/search';
import { useEventSearchContext } from 'features/events/hooks';

interface Options {
  filter?: EventFilter;

  zipFilter?: SearchContextZipFilter;

  sortInfo: EventSort & { proximity?: typeof SORT['desc'] | undefined | null };
}

export function useEventSearch(options?: Options) {
  const [refreshing, setRefreshing] = useState(false);

  const {
    filter: initialFilter,
    searchQuery,
    zipFilter,
    sortOption: sort,
  } = useEventSearchContext();
  const { data: school } = useSchoolConfiguration();

  const startPointZip = zipFilter?.zip ?? '';
  const radius = zipFilter?.distance?.split(' ')[0] ?? '';

  const variables: EventSearchQueryVariables = useMemo(() => {
    let proximitySort: SortOrder | null = null;
    const startZip = isZipCorrect(startPointZip);

    if (startZip && sort.proximity) {
      proximitySort = sort.proximity as SortOrder;
      // Remove the sort proximity to avoid conflicts with the server.
      delete sort.proximity;
    }

    const filter = createEventFilter({
      initial: initialFilter,
      search: searchQuery,
      school,
    });

    const variables: EventSearchQueryVariables = {
      first: PAGE_SIZE,
      sort: sort,
      startPointZip: startZip,
      radius,
      proximitySort,
      filter,
    };

    return variables;
  }, [options, initialFilter, zipFilter, searchQuery, school]);

  const { data: response, loading, fetchMore: next } = useEventSearchQuery({ variables });

  const data = response?.events.items ?? [];
  const count = response?.events.count ?? 0;

  const fetchMore = useCallback(async () => {
    setRefreshing(true);

    try {
      await next({
        variables: {
          skip: data.length,
        },

        updateQuery(prev, { fetchMoreResult: curr }) {
          const oldest = prev?.events.items ?? [];
          const newest = curr?.events?.items ?? [];

          const count = curr?.events.count ?? 0;
          /** Merge between `oldest` items and `newest` reduced by ID. */
          const items = uniqBy(user => user.id, [...oldest, ...newest]);

          const data: EventSearchQuery = {
            ...curr,

            events: {
              ...prev.events,

              count,
              items,
            },
          };

          return data;
        },
      });
    } catch (err) {
      recordError(err);
    }

    setRefreshing(false);
  }, [data.length, next]);

  return {
    data,
    count,
    loading,
    refreshing,
    hasMore: count > data.length,

    fetchMore,
  };
}

const isZipCorrect = (zip: number | string) => {
  const isCorrectZip = !isValidZipCode(zip);
  return isCorrectZip ? zip.toString() : '';
};
