import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { DEFAULT_DATE_RANGE } from 'shared/hooks';

import { DashboardLayout } from '../components';

export function DashboardPage() {
  return (
    <RouteLayout
      content={
        <SearchProvider defaultDateFilter={DEFAULT_DATE_RANGE}>
          <DashboardLayout />
        </SearchProvider>
      }
    />
  );
}
