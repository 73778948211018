import React, { useEffect, useMemo, useState } from 'react';
import { Box } from '@material-ui/core';

import { Button, Modal, TextField } from 'shared/components/ui';

export enum EDIT_SETTINGS_TYPE {
  NAME,
  FULL_NAME,
}

type EditSettingsModalProps = {
  type: EDIT_SETTINGS_TYPE;
  value?: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: (string) => void;
};

export const EditSettingsModal: React.FC<EditSettingsModalProps> = ({
  type,
  value = '',
  isOpen,
  onClose,
  onSave,
}: EditSettingsModalProps) => {
  const [newValue, setNewValue] = useState('');
  const [isError, setError] = useState(false);

  useEffect(() => {
    setNewValue(value || '');
    setError(false);
  }, [isOpen, value]);

  const onChange = e => {
    setNewValue(e.target.value);
    setError(!e.target.value.trim());
  };

  const onSaveInner = () => {
    if (!isError) {
      onSave(newValue);
    }
  };

  const valueName = useMemo(
    () => (type === EDIT_SETTINGS_TYPE.NAME ? 'School Name' : 'School Full Name'),
    [type],
  );

  return (
    <Modal
      dialogProps={{
        open: isOpen,
        onClose,
        fullWidth: true,
      }}
      titleProps={{
        title: `Edit ${valueName}`,
      }}
      isVisibleDivider
    >
      <Box display="grid" gridTemplateRows="auto" gridGap={2} my={2}>
        <TextField
          label={`New ${valueName}`}
          variant="outlined"
          value={newValue}
          onChange={onChange}
          error={isError}
          helperText={isError ? `Enter ${valueName} please` : ''}
        />
      </Box>

      <Box display="flex" justifyContent="end" gridGap="0.5rem">
        <Button onClick={onClose}>Cancel</Button>

        <Button
          variant="contained"
          color="primary"
          type="submit"
          onClick={onSaveInner}
          disabled={isError}
          disableElevation
        >
          Save Changes
        </Button>
      </Box>
    </Modal>
  );
};
