export const DATE_USA_FORMAT = 'MM-dd-yyyy';
export const DATE_SQL_FORMAT = 'yyyy-MM-dd';
export const DATE_SHORT_FORMAT = 'yyyyMMdd';
export const DATE_TABLE_FORMAT = 'MM/dd/yyyy';
export const DATE_YEAR_FORMAT = 'yyyy';
export const DATE_MONTH_FORMAT = 'mm';
export const DATE_DAY_FORMAT = 'dd';
export const REGEX_USA_FORMAT = /^\d{2}-\d{2}-\d{4}$/i;
export const REGEX_SQL_FORMAT = /^\d{4}-\d{2}-\d{2}$/i;
/** @documentation [ISO8601](https://en.wikipedia.org/wiki/ISO_8601) extended format. */
export const REGEX_ISO8601_FORMAT = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i;
export const REGEX_TABLE_FORMAT = /^\d{2}\/\d{2}\/\d{4}$/i;
export const MIN_PLATFORM_DATE = '2022-01-01';
