import { Fragment, useCallback, useMemo } from 'react';
import { Forum } from '@material-ui/icons';

import { SORT, SystemRoleName, UserSchoolMembershipStatus } from '@jebel/constants';
import {
  canSchoolMembershipBeApproved,
  canSchoolMembershipBeRejected,
  createFilterBuilder,
} from '@jebel/utils';

import { Icon, Modal } from 'shared/components/ui';
import { SNACKBAR_ERROR_MESSAGE, SNACKBAR_SUCCESS_MESSAGE } from 'shared/constants';
import { useSpreadsheetSearch } from 'shared/features/search';
import {
  HeadlinesType,
  Spreadsheet,
  SpreadsheetCellActions,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';
import {
  SortOrder,
  User,
  SchoolMembersReportQuery,
  UserFilter,
  useUserUpdateMutation,
  UserSchoolMembershipSort,
  SchoolMembersReportQueryVariables,
  UserSchoolMembershipFilter,
} from 'shared/graphql';
import { useInboxContext } from 'providers/InboxProvider';
import { formatToPhone } from 'shared/utils/form';
import { MemberEditRolesModal } from 'admin-features/members/components/MemberEditRolesModal';
import {
  useAudienceGraduatingYearsOptions,
  useCurrentUserOrganizations,
  useDownloadLazyQueryCSV,
  useModalState,
  useSchoolConfiguration,
} from 'shared/hooks';
import { formatUserName } from 'shared/utils/user';
import { formatTableDate } from 'shared/utils/date';
import { SiteAdminsHeaders } from 'admin-features/settings/constants';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import { memberSpreadsheetFilters } from '../../../members/constants';
import { getUserStatusComponent } from '../../../members/components/MembersSpreadsheet';
import { SCHOOL_MEMBERS_REPORT_QUERY } from '../../../members/queries';
import { useAcceptMember, useRejectMember } from 'admin-features/members/hooks';

export const SiteAdminsSpreadsheetHeader: HeadlinesType<UserSchoolMembershipSort> = [
  {
    name: 'fullName',
    label: 'NAME',
    id: 'fullName',
    sortPath: (order: SortOrder) => ({ user: { fullName: order } }),
  },
  {
    name: 'email',
    label: 'EMAIL ADDRESS',
    id: 'email',
    sortPath: (order: SortOrder) => ({ user: { email: order } }),
  },
  {
    name: 'phone',
    label: 'PHONE',
    id: 'phoneNumber',
    sortPath: (order: SortOrder) => ({ user: { userPreferences: { phoneNumber: order } } }),
  },
  {
    name: 'birth',
    label: 'DATE OF BIRTH',
    id: 'birthDate',
    sortPath: (order: SortOrder) => ({ user: { birthDate: order } }),
  },
  {
    name: 'status',
    label: 'STATUS',
    id: 'status',
    sortPath: (order: SortOrder) => ({ status: order }),
  },
];

const ADMIN_ROLE_ADDON = 'Community Admin';
const ADMIN_ROLE = 'CommunityAdmin';

const FALLBACK_RADIUS = '-';
const FALLBACK_START_POINT_ZIP = '';
const FALLBACK_SORT: UserSchoolMembershipSort = { user: { fullName: SORT.desc } };

export function SiteAdminsList() {
  const { data: organizations } = useCurrentUserOrganizations({
    variables: { sort: { name: SORT.asc } },
  });

  const { data: school } = useSchoolConfiguration();
  const { onOpenInboxModal } = useInboxContext();
  const { data: graduatingYears } = useAudienceGraduatingYearsOptions();

  const { queryParams, selected, currentRowId, zip } =
    useSpreadsheetContext<SchoolMembersReportQueryVariables>();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<UserSchoolMembershipFilter>(queryParams.filter);

    filter.and({
      user: {
        // Filter by users who have the role of "CommunityAdmin" within any role addon as system-role.
        rolesAddons: { some: { role: { name: { equals: SystemRoleName.CommunityAdmin } } } },
      },
    });

    return filter.build();
  }, [queryParams]);

  const { tableData, queryVariables, tableLoading } = useSpreadsheetSearch<
    SchoolMembersReportQuery,
    SchoolMembersReportQueryVariables
  >({
    query: SCHOOL_MEMBERS_REPORT_QUERY,
    searchingFields: ['user.fullName', 'user.email', 'user.userPreferences.phoneNumber'],
    skip: !school?.id,
    queryVariables: {
      ...queryParams,

      school: { id: school?.id },
      startPointZip: zip.startPointZip ?? FALLBACK_START_POINT_ZIP,
      radius: zip.radius ?? FALLBACK_RADIUS,
      sort: queryParams.sort ?? FALLBACK_SORT,
      filter,
    },
  });

  const siteAdminsData = useMemo(
    () =>
      tableData?.members?.items.map(el => ({
        id: el.id || '',
        fullName: formatUserName(el),
        email: el.email || '',
        phone: formatToPhone(el.phoneNumber),
        birth: el?.birthDate ? formatTableDate(el.birthDate) : '(Unknown)',
        status: getUserStatusComponent(el.status),
      })) || [],
    [tableData],
  );

  const { mutate: acceptRequest } = useAcceptMember({
    refetchQueries: ['SchoolMembersReport', 'SchoolMembersReportCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your invitations have been sent. Thank you.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your invitations haven't been sent`,
    },
  });

  const { mutate: rejectMember } = useRejectMember({
    refetchQueries: ['SchoolMembersReport', 'SchoolMembersReportCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your request has been sent.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const [updateUser] = useUserUpdateMutation({
    refetchQueries: ['SchoolMembersReport', 'SchoolMembersReportCount'],
    context: {
      [SNACKBAR_SUCCESS_MESSAGE]: 'Success! Your request has been sent.',
      [SNACKBAR_ERROR_MESSAGE]: `Error! Your request haven't been sent`,
    },
  });

  const {
    show: isEditRolesModalOpen,
    open: onEditRolesModalOpen,
    close: onEditRolesModalClose,
    params: editRolesModalAdminId,
  } = useModalState<string>();

  const spreadsheetActions = useMemo((): SpreadsheetCellActions => {
    const selectedAdmin = tableData?.members.items.find(user => user.id === currentRowId) as
      | User
      | undefined;

    const adminRoleAddonID =
      selectedAdmin?.rolesAddons?.items.find(({ name }) => name === ADMIN_ROLE_ADDON)?.id || '';

    const adminRoleID =
      selectedAdmin?.roles?.items.find(({ name }) => name === ADMIN_ROLE)?.id || '';

    const withApprove = canSchoolMembershipBeApproved(selectedAdmin?.status);
    const withReject = canSchoolMembershipBeRejected(selectedAdmin?.status);

    const options: SpreadsheetCellActions = [
      {
        id: 'edit_admin_roles',
        title: 'Edit Admin Roles',
        onClickAction: (id: string) => {
          onEditRolesModalOpen(id);
        },
        icon: <Icon name="PersonAddAlt1" />,
      },
      {
        id: 'Delete',
        title: 'Delete',
        onClickAction: (id: string) => {
          updateUser({
            variables: {
              data: {
                id,
                roles: {
                  disconnect: [{ id: adminRoleID }],
                },
                rolesAddons: {
                  disconnect: [{ id: adminRoleAddonID }],
                },
              },
            },
          });
        },

        icon: <Icon name="Delete" />,
      },
    ];

    if (withApprove) {
      options.push({
        id: 'Approve',
        title: 'Approve Request',
        icon: <Icon name="CheckCircle" />,
        onClickAction: (id: string) => {
          acceptRequest({ id });
        },
      });
    }

    if (withReject) {
      options.push({
        id: 'Reject',
        title: 'Reject Request',
        icon: <Icon name="DeleteForeverSharp" />,
        onClickAction: (id: string) => {
          rejectMember({ id });
        },
      });
    }

    return options;
  }, [
    tableData?.members.items,
    currentRowId,
    selected,
    acceptRequest,
    onEditRolesModalOpen,
    updateUser,
  ]);

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<UserFilter>(queryParams.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryParams, selected]);

  const [downloadHandler] = useDownloadLazyQueryCSV<SchoolMembersReportQuery>(
    SCHOOL_MEMBERS_REPORT_QUERY,
    {
      filename: getFileNameWithTimestamp('SiteAdmins.csv'),

      variables: {
        startPointZip: queryVariables.startPointZip ?? FALLBACK_START_POINT_ZIP,
        radius: queryVariables.radius ?? FALLBACK_RADIUS,
        filter: {
          ...downloadFilter,
          rolesAddons: { some: { name: { equals: ADMIN_ROLE_ADDON } } },
        },
      },

      transform(response) {
        const members = response?.members?.items ?? [];

        return members.map(member => {
          const birthDate = member?.birthDate ? formatTableDate(member.birthDate) : '(Unknown)';

          return {
            [SiteAdminsHeaders.name]: formatUserName(member),
            [SiteAdminsHeaders.email]: member.email,
            [SiteAdminsHeaders.phone]: formatToPhone(member.phoneNumber),
            [SiteAdminsHeaders.dateOfBirth]: birthDate,
            [SiteAdminsHeaders.status]: member.status ?? UserSchoolMembershipStatus.Pending,
          };
        });
      },
    },
  );

  const handleMessage = useCallback(
    (adminsIds: string[]) => {
      const listOfAdmins =
        tableData?.members.items.filter(({ id }) => adminsIds.includes(id ?? '')) || [];

      onOpenInboxModal?.({
        isOpen: true,
        options: {
          members: listOfAdmins?.map(user => user as User),
          messageType: 'personal',
        },
      });
    },
    [onOpenInboxModal, tableData?.members.items],
  );

  const mainToolbarAction = useMemo(
    () => ({
      icon: <Forum />,
      label: 'Message',
      onClick: handleMessage,
    }),
    [handleMessage],
  );

  return (
    <Fragment>
      <Modal
        dialogProps={{ open: isEditRolesModalOpen, onClose: onEditRolesModalClose }}
        titleProps={{ title: 'Edit User Roles' }}
      >
        <MemberEditRolesModal
          memberId={editRolesModalAdminId}
          onModalClose={onEditRolesModalClose}
        />
      </Modal>

      <Spreadsheet
        data={siteAdminsData}
        headlines={SiteAdminsSpreadsheetHeader}
        toolbarOptions={{
          filters: memberSpreadsheetFilters({ organizations, graduatingYears }),
          mainToolbarAction,
          withPerPage: true,
          withDownload: true,
          downloadHandler,
          rawData: tableData?.members?.items ?? [],
        }}
        cellActions={spreadsheetActions}
        itemsCount={tableData?.members?.count ?? 0}
        loading={tableLoading}
      />
    </Fragment>
  );
}
