import { MutationHookOptions } from '@apollo/client';

import {
  useSendSchoolMemberInvitesMutation,
  useSendBusinessManagerInvitesMutation,
  useSendSchoolAdministratorInvitesMutation,
  SendSchoolMemberInviteInput,
  SendSchoolAdministratorInviteInput,
  SendBusinessManagerInviteInput,
  useSendBusinessAdministratorInvitesMutation,
  SendBusinessAdministratorInviteInput,
} from 'shared/graphql';

import { useSchoolConfiguration } from './useSchoolConfiguration';
import { useCurrentUserPermissions } from './useCurrentUserPermissions';

type Options = Omit<MutationHookOptions, 'variables'>;

export function useUserInvitation(options?: Options) {
  const { isSchoolAdmin: isAdmin } = useCurrentUserPermissions();
  const { data: school } = useSchoolConfiguration();

  const [sendSchoolMemberInvitation, { loading: isSendingSchoolMemberInvites }] =
    useSendSchoolMemberInvitesMutation(options);

  const [sendSchoolAdminInvitation, { loading: isSendingSchoolAdminInvites }] =
    useSendSchoolAdministratorInvitesMutation(options);

  const [sendBusinessManagerInvite, { loading: isSendingBusinessManagerInvites }] =
    useSendBusinessManagerInvitesMutation(options);

  const [sendBusinessAdminInvitation, { loading: isSendingBusinessAdminInvites }] =
    useSendBusinessAdministratorInvitesMutation(options);

  const isInviting =
    isSendingSchoolMemberInvites ||
    isSendingSchoolAdminInvites ||
    isSendingBusinessManagerInvites ||
    isSendingBusinessAdminInvites;

  return {
    isInviting,

    /** Send the invitations to another users. In case `isAdminInviting` and indeed has the rights will approve those users. */
    async inviteUsers(invites: SendSchoolMemberInviteInput[], isAdminInviting = isAdmin) {
      await sendSchoolMemberInvitation({
        variables: {
          invites,
          school: { id: school?.id },
          isSchoolAdministratorInviting: isAdminInviting,
        },
      });
    },

    /** Send the invitations to another users. In case `isAdminInviting` and indeed has the rights will approve those users. */
    async inviteAdmins(invites: SendSchoolAdministratorInviteInput[]) {
      await sendSchoolAdminInvitation({
        variables: {
          invites,
          school: { id: school?.id },
        },
      });
    },

    /** Invite users as business managers to a given `business` and approve their incorporation. */
    async inviteBusinessManagers(invites: SendBusinessManagerInviteInput[], businessId: string) {
      return sendBusinessManagerInvite({
        variables: {
          invites,
          organization: { id: businessId },
        },
      });
    },

    /** Invite users as business administrators and approve their incorporation. */
    async inviteBusinessAdmins(invites: SendBusinessAdministratorInviteInput[]) {
      await sendBusinessAdminInvitation({
        variables: {
          invites,
          school: { id: school?.id },
        },
      });
    },
  };
}
