import { SORT } from '@jebel/constants';

import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import { DEFAULT_DATE_RANGE } from 'shared/hooks';

import { ReportsContent } from '../components';
import { SORT_TYPES } from '../constants';

const FALLBACK_SORT = {
  order: SORT.desc,
  headcellId: SORT_TYPES.postDate,
  sort: {
    createdAt: SORT.desc,
  },
};

export function ReportsPage() {
  return (
    <SearchProvider defaultDateFilter={DEFAULT_DATE_RANGE}>
      <RouteLayout
        content={
          <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
            <ReportsContent />
          </SpreadsheetProvider>
        }
      />
    </SearchProvider>
  );
}
