import { lazy, Suspense, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import styled from '@emotion/styled';

import { SORT } from '@jebel/constants';

import { RouteLayout } from 'shared/components/layouts';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';
import { SearchProvider } from 'shared/features/search';
import { DashboardPageSkeleton } from 'shared/components/ui';

const DEFAULT_SORT_OPTION = {
  order: SORT.desc,
  headcellId: 'updatedAt',
  sort: {
    updatedAt: SORT.desc,
  },
};

const ContentCurationContent = lazy(() => import('../components/ContentCurationContent'));

export function ContentCurationPage() {
  return (
    <RouteLayout
      content={
        <Suspense fallback={<DashboardPageSkeleton />}>
          <Content>
            <Header>
              <Typography variant="subtitle2" color="primary">
                Content Curation
              </Typography>
            </Header>

            <SpreadsheetProvider defaultPageSize={5} defaultSortOption={DEFAULT_SORT_OPTION}>
              <SearchProvider>
                <ContentCurationContent />
              </SearchProvider>
            </SpreadsheetProvider>
          </Content>
        </Suspense>
      }
    />
  );
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
