import { useState } from 'react';
import { DateTime } from 'luxon';

export interface DateRange {
  startDate: DateTime | null;
  endDate: DateTime | null;
}

/**
 * The default date filter state.
 * The `startDate` is the beginning of the current week.
 * The `endDate` is the end of the current day.
 */
export const DEFAULT_DATE_RANGE: DateRange = {
  startDate: DateTime.now().startOf('week').setLocale('en-US'),
  endDate: DateTime.now().endOf('day').setLocale('en-US'),
};

/**
 * A hook for managing date filter state with a start and end date.
 *
 * @param initialState - The initial date filter state or a function that returns the initial state.
 * Defaults to the default date filter.
 *
 * @returns A tuple containing:
 * - The current date filter state
 * - A function factory that creates setters for individual date filter fields
 *
 * @example
 * ```tsx
 * const [range, createSetter] = useDateRangeState();
 *
 * const setStartDate = createSetter('startDate');
 * const setEndDate = createSetter('endDate');
 * ```
 */
export function useDateRangeState(
  initialState: DateRange | (() => DateRange) = DEFAULT_DATE_RANGE,
) {
  const [value, setValue] = useState(initialState);

  const createSetter = (key: keyof DateRange) => {
    return (incoming: DateTime) => {
      setValue(prev => ({ ...prev, [key]: incoming }));
    };
  };

  return [value, createSetter] as const;
}
