import { useCallback } from 'react';
import { css } from '@emotion/react';
import { TableRow } from '@mui/material';

import {
  HeadlinesType,
  SpreadsheetDataType,
  SpreadsheetCell,
  useSpreadsheetContext,
} from 'shared/features/spreadsheet';

const rowCss = css`
  cursor: pointer;

  &:hover {
    box-shadow: 0 3px 15px 0 #ebebeb;
  }
`;

type SpreadsheetRowProps<T extends HeadlinesType> = {
  index: number;
  data: SpreadsheetDataType<T>;
  headlines: T;
};

export function SpreadsheetRow<T extends HeadlinesType>({
  index,
  data,
  headlines,
}: SpreadsheetRowProps<T>) {
  const { setCurrentRowId } = useSpreadsheetContext();

  const onRowClick = useCallback(() => setCurrentRowId(data.id), [data.id, setCurrentRowId]);

  return (
    <TableRow css={rowCss} onClick={onRowClick}>
      {headlines.map(headline => (
        <SpreadsheetCell
          key={headline.id}
          index={index}
          data={data}
          value={data[headline.name]}
          headline={headline}
        />
      ))}
    </TableRow>
  );
}
