import { DATE_SQL_FORMAT, DATE_TABLE_FORMAT, DATE_USA_FORMAT, REGEX_SQL_FORMAT, REGEX_ISO8601_FORMAT, } from '@jebel/constants';
import { currentDateTime, parseDate } from './parseDates';
import { DateTime } from 'luxon';
import { isObj } from '../types';
/**
 * Transform a date into a formatted string based on a template.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 * @param template String with the template to [follow the format](https://moment.github.io/luxon/#/formatting?id=toformat).
 *
 * @throws If the date is invalid or the template is incorrect.
 */
export function formatDate(value, template, options) {
    const date = parseDate(value, options);
    if (date.isValid && date.invalidReason) {
        throw new Error(date.invalidReason);
    }
    if (isObj(template)) {
        return date.toLocaleString(template);
    }
    return date.toFormat(template);
}
/**
 * Transform a date into milliseconds.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 */
export function formatMilliseconds(value = currentDateTime(), options) {
    const date = parseDate(value, options);
    return date.toMillis();
}
/**
 * Formats a date value into a short date string.
 *
 * @param value - The date value to be formatted.
 * @param options - Optional configuration for parsing the date.
 * @param options.omitYear - Allows omitting the year if the date is within the current year. Defaults to `true`.
 * @returns The formatted date string.
 */
export function formatShortDate(value, options) {
    var _a;
    const date = parseDate(value, options);
    const now = currentDateTime();
    const omitYear = (_a = options === null || options === void 0 ? void 0 : options.omitYear) !== null && _a !== void 0 ? _a : true;
    if (omitYear && date.year === now.year) {
        return formatDate(value, 'MMMM dd');
    }
    return formatDate(value, 'MMMM dd, yyyy');
}
/**
 * Formats a given date value into a human-readable string with the format "EEEE, MMMM dd, yyyy".
 *
 * @param value - The date value to be formatted. It can be a Date object, a string, or a number.
 * @param options - Optional. Additional options for parsing the date value.
 * @returns The formatted date string.
 */
export function formatHugeDate(value = currentDateTime(), options) {
    return formatDate(value, DateTime.DATE_HUGE, options);
}
/**
 * Transform a date using the `yyyy-MM-dd` format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 */
export function formatSQLDate(value = currentDateTime(), options) {
    if (typeof value === 'string' && REGEX_SQL_FORMAT.test(value)) {
        return value;
    }
    return formatDate(value, DATE_SQL_FORMAT, options);
}
/**
 * Transform a date using the [ISO8601](https://en.wikipedia.org/wiki/ISO_8601) .
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 */
export function formatISO8601Date(value = currentDateTime(), options) {
    if (typeof value === 'string' && REGEX_ISO8601_FORMAT.test(value)) {
        return value;
    }
    const date = parseDate(value, options);
    return date.toISO({ format: 'extended' });
}
/**
 * Transform a date into a formatted string using the USA date format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 * @returns Given `value` with `MM-dd-yyyy` date format.
 */
export function formatUSADate(value = currentDateTime(), options) {
    return formatDate(value, DATE_USA_FORMAT, options);
}
/**
 * Transform a date using the `MM/dd/yyyy` format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 * @returns Given `value` with `MM/dd/yyyy` date format.
 */
export function formatTableDate(value = currentDateTime(), options) {
    return formatDate(value, DATE_TABLE_FORMAT, options);
}
/**
 * Transform a date using the `MMMM dd, yyyy 'at' TTT` format.
 * @param value Date on available formats (ISO, UNIX, SQL or JS Date). By default, it uses the current date and time.
 * @example `January 01, 2024 at 13:00:00 EDT`
 * @returns Given `value` with `MMMM dd, yyyy 'at' TTT` date format.
 */
export function formatLongDate(value = currentDateTime(), options) {
    return formatDate(value, `MMMM dd, yyyy 'at' TTT`, options);
}
