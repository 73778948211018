import { Dispatch, SetStateAction } from 'react';

import { type SortInfoOption } from 'shared/components/ui';

export interface SearchContextZipFilter {
  zip: string;
  distance: string;
}

export const DEFAULT_SEARCH_CONTEXT_ZIP_FILTER: SearchContextZipFilter = {
  zip: '',
  distance: '-',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface SearchContextValue<F = any, D = any> {
  searchQuery: string;
  setSearchQuery: Dispatch<SetStateAction<string>>;
  isUserTyping: boolean;
  setIsUserTyping: Dispatch<SetStateAction<boolean>>;
  setSortOption: Dispatch<SetStateAction<SortInfoOption>>;
  sortOption: SortInfoOption;
  displayList: boolean;
  setDisplayList(value: boolean | undefined): void;
  filter: F;
  setFilter: Dispatch<SetStateAction<F>>;
  dateFilter: D;
  setDateFilter: Dispatch<SetStateAction<D>>;
  zipQuery: string;
  setZipQuery: Dispatch<SetStateAction<string>>;

  /**
   * Zip filter for search context.
   * @default undefined
   */
  zipFilter: SearchContextZipFilter | null;

  /**
   * Set zip filter for search context.
   */
  setZipFilter: Dispatch<SetStateAction<SearchContextZipFilter>>;

  isFilterModalOpen: boolean;
  /** @deprecated Use `openFilterModal` or `closeFilterModal` instead. */
  setIsFilterModalOpen: Dispatch<SetStateAction<boolean>>;

  /**
   * Open the filter modal
   */
  openFilterModal(): void;

  /**
   * Close the filter modal
   */
  closeFilterModal(): void;
}
