import { useMemo, useState } from 'react';
import { Box } from '@material-ui/core';

import { CardLayout, FeedLayout } from 'shared/components/layouts';
import { Button, Modal, Typography } from 'shared/components/ui';
import { USER_SORT_INFO, FIELDS_CONFIG, SELECT_OPTION_ALL } from 'shared/constants';
import { SearchControl, SearchProvider } from 'shared/features/search';
import { useCurrentUser, usePageTitle, useResponsive } from 'shared/hooks';

import { CareerFilter, CareerServicesContent, SavedJobs, CreateJobPostModal } from '../components';
import { CAREERS_SORT_INFO } from '../constants';
import { SearchCareerFilter } from '../types';

export function CareerServicesPages() {
  const [isJobPostModalOpen, setJobPostModalOpen] = useState(false);

  const [selectedFilters, setSelectedUserFilters] = useState<SearchCareerFilter>({
    type: 'Jobs',
    radius: '-',
  });

  const title = usePageTitle({ fallback: 'Career Services' });

  const { isMobile } = useResponsive();
  const { isAdmin, isBusinessAdminOrManager } = useCurrentUser();

  const isFilterTypeAll = useMemo(() => {
    return selectedFilters.type === SELECT_OPTION_ALL;
  }, [selectedFilters]);

  const searchControlProps = useMemo(() => {
    if (selectedFilters.type === FIELDS_CONFIG.type.jobs) {
      return {
        placeholder: 'Search Careers',
        sortInfo: CAREERS_SORT_INFO,
        fullWidth: true,
      };
    }

    if (selectedFilters.type === FIELDS_CONFIG.type.jobSeekers) {
      return {
        placeholder: 'Search Job Seekers',
        sortInfo: USER_SORT_INFO,
        fullWidth: true,
      };
    }

    if (selectedFilters.type === FIELDS_CONFIG.type.internships) {
      return {
        placeholder: 'Search Internships',
        sortInfo: CAREERS_SORT_INFO,
        fullWidth: true,
      };
    }

    return {
      // None of the data-structures are compatible within their fields to be sorted by.
      // https://8base-dev.atlassian.net/browse/JEB-1594?focusedCommentId=45457
      disabled: true,
      placeholder: 'Search All',
      sortInfo: undefined,
      fullWidth: true,
    };
  }, [selectedFilters.type]);

  const onJobPostClose = () => {
    setJobPostModalOpen(false);
  };

  return (
    <SearchProvider defaultSortOption={CAREERS_SORT_INFO.options[0].value}>
      <FeedLayout
        sidebar={
          <Box display="grid" gridGap="1rem">
            <CardLayout>
              <CareerFilter selectedFilter={selectedFilters} setFilters={setSelectedUserFilters} />
            </CardLayout>

            <SavedJobs />
          </Box>
        }
      >
        <Modal
          dialogProps={{
            open: isJobPostModalOpen,
            onClose: onJobPostClose,
            fullWidth: true,
            maxWidth: !isMobile ? 'xs' : 'xs',
          }}
          titleProps={{ title: 'Create Job Post' }}
          isVisibleDivider
          dialogContentWidth={280}
        >
          <CreateJobPostModal onModalClose={onJobPostClose} />
        </Modal>

        <CardLayout>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant={isMobile ? 'subtitle3' : 'subtitle2'} color="primary">
              {title}
            </Typography>

            {(isAdmin || isBusinessAdminOrManager) && (
              <Button color="primary" variant="contained" onClick={() => setJobPostModalOpen(true)}>
                Post
              </Button>
            )}
          </Box>

          <Box>
            <SearchControl
              withTextField
              withSortField={!isFilterTypeAll}
              withFilterModal={isMobile}
              textFieldProps={searchControlProps}
              sortFieldProps={searchControlProps}
              elementsWidth={
                isMobile ? ['1fr', 'max-content'] : isFilterTypeAll ? ['1fr'] : ['1fr', '10rem']
              }
            />
          </Box>
        </CardLayout>

        <CareerServicesContent
          selectedFilters={selectedFilters}
          setFilters={setSelectedUserFilters}
        />
      </FeedLayout>
    </SearchProvider>
  );
}
