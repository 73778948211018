import { gql } from '@apollo/client';

export const DISCOUNT_STATS_QUERY = gql`
  query DiscountsStats($school: ID!) {
    activeDiscounts: discountsList(
      filter: { schools: { some: { id: { equals: $school } } }, status: { equals: "active" } }
    ) {
      count
    }

    totalDiscounts: discountRedemptionsList(
      filter: {
        school: { id: { equals: $school } }
        createdAt: { relative: { gte: { op: SUB, unit: DAY, value: "30" } } }
      }
      groupBy: {
        query: {
          id: { as: "count", fn: { aggregate: COUNT } }
          discount: { id: { as: "id" } }
          createdAt: { as: "date", fn: { datePart: DATE } }
        }
      }
    ) {
      items: groups {
        id: ID
        count: Int
      }
    }
  }
`;

export const ADMIN_DISCOUNT_FRAGMENT = gql`
  fragment AdminDiscount on Discount {
    id
    createdAt
    title
    type
    amountPercent
    startDate
    expirationDate
    timezone
    category
    status
    isSchoolDiscount
    terms

    images {
      items {
        id
        fileId
        filename
        public
        downloadUrl
      }
    }

    organization {
      id
      name
    }

    redemptions {
      count
    }
  }
`;

export const ADMIN_DISCOUNTS_LIST_QUERY = gql`
  query AdminDiscountsSpreadsheetList(
    $first: Int
    $skip: Int
    $filter: DiscountFilter
    $sort: [DiscountSort!]
  ) {
    discounts: discountsList(first: $first, skip: $skip, filter: $filter, sort: $sort) {
      count
      items {
        ...AdminDiscount
      }
    }
  }

  ${ADMIN_DISCOUNT_FRAGMENT}
`;

export const ADMIN_DISCOUNTS_LIST_COUNT_QUERY = gql`
  query AdminDiscountsSpreadsheetListCount($filter: DiscountFilter) {
    discountsList(filter: $filter) {
      count
    }
  }
`;

export const DISCOUNT_CREATE_REQUEST_MUTATION = gql`
  mutation DiscountCreateRequest($data: DiscountCreateRequestInput!) {
    discountCreateRequest(data: $data) {
      id
    }
  }
`;

export const ADMIN_DISCOUNT_UPDATE_MUTATION = gql`
  mutation AdminUpdateDiscount($data: DiscountUpdateInput!) {
    discountUpdate(data: $data) {
      ...AdminDiscount
    }
  }

  ${ADMIN_DISCOUNT_FRAGMENT}
`;

export const DISCOUNT_UPDATE_STATUS_REQUEST_MUTATION = gql`
  mutation DiscountUpdateStatusRequest($data: DiscountUpdateStatusRequestInput!) {
    discountUpdateStatusRequest(data: $data) {
      success
    }
  }
`;
