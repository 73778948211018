export var BillingType;
(function (BillingType) {
    /** Use with charges or deposits related with advertising. */
    BillingType["Advertising"] = "ad";
    /** Use with charges or deposits related with special offers. */
    BillingType["SpecialOffer"] = "specialOffer";
    /** Use with charges or deposits related with school support subscription plan. */
    BillingType["SchoolSupport"] = "schoolSupport";
    /** Use when a new payment method is added to the organization. */
    BillingType["CreatePaymentMethod"] = "createPayment";
})(BillingType || (BillingType = {}));
export var BillPlanStatus;
(function (BillPlanStatus) {
    /** The bill plan have been paid. */
    BillPlanStatus["Paid"] = "paid";
    /** The bill plan is scheduled to be paid. */
    BillPlanStatus["NotPaid"] = "notPaid";
})(BillPlanStatus || (BillPlanStatus = {}));
export var DebtOperationType;
(function (DebtOperationType) {
    /** Use with operations that are deposits, increase the debt of the organization. */
    DebtOperationType["Deposit"] = "deposit";
    /** Use with operations that are charges, reduce the debt of the organization. */
    DebtOperationType["Charge"] = "charge";
})(DebtOperationType || (DebtOperationType = {}));
export { 
/** @deprecated Use {@linkcode BillPlanStatus} instead. */
BillPlanStatus as BillingRegisteredSchoolSupportStatus, 
/** @deprecated Use {@linkcode BillPlanStatus} instead. */
BillPlanStatus as BillingRegisteredSpecialOfferStatus, 
/** @deprecated Use {@linkcode BillPlanStatus} instead. */
BillPlanStatus as BillingRegisteredAdvertisingStatus, };
