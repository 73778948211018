import { useActivateSchoolMembersMutation, UserKeyFilter } from 'shared/graphql';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { recordError } from 'shared/utils/record';

const ACTIVATING_MEMBER_MESSAGE_KEY = 'ACTIVATING_MEMBER_MESSAGE';

type Options = Parameters<typeof useActivateSchoolMembersMutation>[0];

/**
 * Custom hook to activate a school membership.
 *
 * This hook provides a function to activate a school membership for a school and handles
 * success and error notifications.
 *
 * @returns An object containing:
 * - `mutate`: A function to activate a school membership.
 * - `loading`: A boolean indicating if the mutation is in progress.
 *
 * @example
 * const { mutate, loading } = useActivateMember();
 *
 * const handleApprove = async (user) => {
 *   await mutate(user);
 * };
 */
export function useActivateMember(options?: Options) {
  const { showMessage, showSuccess, showError, dismiss } = useToast();
  const { data: school } = useSchoolConfiguration();

  const [mutation, { loading }] = useActivateSchoolMembersMutation(options);

  const mutate = async (user: UserKeyFilter) => {
    if (!school) {
      return;
    }

    try {
      showMessage(`Activating school membership "${user.email ?? user.id}".`, {
        id: ACTIVATING_MEMBER_MESSAGE_KEY,
      });

      await mutation({
        variables: {
          filter: { OR: [{ id: { equals: user.id } }, { email: { equals: user.email } }] },
          school: { id: school.id },
        },
      });

      showSuccess(`Member "${user.email ?? user.id}" has been activated`);
    } catch (err) {
      recordError(err);

      if (err instanceof Error) {
        showError(err.message, { reportable: false });
      }
    } finally {
      dismiss(ACTIVATING_MEMBER_MESSAGE_KEY);
    }
  };

  return {
    mutate,
    loading,
  };
}
