import { lazy, Suspense } from 'react';
import { css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { SUPPORTERS } from 'shared/constants';
import { FeedLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { EventFilter } from 'shared/graphql';
import { FeedSkeleton } from 'shared/components/ui';

const CommunitySidebar = lazy(() => import('shared/features/community-sidebar'));
const EventsHeader = lazy(() => import('../components/EventsHeader'));
const EventsContent = lazy(() => import('../components/EventsContent'));
const EventFilterSidebar = lazy(() => import('../components/Events/EventFilterSidebar'));

const sidebarCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export function EventsPage() {
  return (
    <SearchProvider<EventFilter, never>>
      <FeedLayout
        sidebar={
          <Suspense fallback={<FeedSkeleton />}>
            <Box css={sidebarCSS}>
              <EventFilterSidebar />
              <CommunitySidebar items={[SUPPORTERS]} />
            </Box>
          </Suspense>
        }
      >
        <Suspense fallback={<FeedSkeleton />}>
          <EventsHeader />
          <EventsContent />
        </Suspense>
      </FeedLayout>
    </SearchProvider>
  );
}
