import { useMemo } from 'react';

import { createFilterBuilder, formatISO8601Date, ParseDateValue } from '@jebel/utils';

import {
  DiscountFilter,
  DiscountRedemptionFilter,
  useSpecialOffersCountQuery,
} from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

interface Params {
  /**
   * Start date for the filter.
   */
  startDate?: ParseDateValue;

  /**
   * End date for the filter.
   */
  endDate?: ParseDateValue;
}

export function useSpecialOffersCount(params: Params = {}) {
  const { data: school } = useSchoolConfiguration();

  const activeDiscountFilter = useMemo(() => {
    const filter = createFilterBuilder<DiscountFilter>();

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1675
      filter.and({ schools: { some: { id: { equals: school.id } } } });
    }

    if (params.startDate) {
      filter.and({ createdAt: { gte: formatISO8601Date(params.startDate) } });
    }

    if (params.endDate) {
      filter.and({ createdAt: { lte: formatISO8601Date(params.endDate) } });
    }

    return filter.build();
  }, [params, school]);

  const redemptionFilter = useMemo(() => {
    const filter = createFilterBuilder<DiscountRedemptionFilter>();

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1675
      filter.and({ school: { id: { equals: school.id } } });
    }

    if (params.startDate) {
      filter.and({ discount: { createdAt: { gte: params.startDate } } });
    }

    if (params.endDate) {
      filter.and({ discount: { createdAt: { lte: params.endDate } } });
    }

    return filter.build();
  }, [params, school]);

  const { data: response, loading } = useSpecialOffersCountQuery({
    skip: !school,
    variables: {
      activeDiscountFilter,
      redemptionFilter,
    },
  });

  const activeOffersCount = response?.activeOffers?.count ?? 0;
  const redeemedOffersCount = response?.redemptions?.count ?? 0;

  return {
    activeOffersCount,
    redeemedOffersCount,
    loading,
  };
}
