import BusinessIcon from '@mui/icons-material/Business';
import { useTheme } from '@material-ui/core';

import { DISTANCE_FILTER_IN_MILES } from '@jebel/constants';

import { DEFAULT_SEARCH_CONTEXT_ZIP_FILTER, useSearchContext } from 'shared/features/search';
import { SelectMenu } from 'shared/components/ui';

import { DashboardCountStatsItem } from '../DashboardCountStats/DashboardCountStatsItem';
import { useBusinessesCount } from '../../../hooks';
import { formatDecimalNumbers } from '../../../../../shared/utils/numbers/formatDecimalNumbers';
import { Container, ZipCode } from './DashboardCountBusinessWrapper.styles';

export function DashboardCountBusinessWrapper() {
  const theme = useTheme();

  const {
    dateFilter,
    zipFilter = DEFAULT_SEARCH_CONTEXT_ZIP_FILTER,
    setZipFilter,
  } = useSearchContext();

  const zip = zipFilter?.zip ?? DEFAULT_SEARCH_CONTEXT_ZIP_FILTER.zip;
  const distance = zipFilter?.distance ?? DEFAULT_SEARCH_CONTEXT_ZIP_FILTER.distance;

  const {
    businessesCount,
    loading: businessLoading,
    supportersCount,
  } = useBusinessesCount({
    zipFilter,
    dateFilter: {
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    },
  });

  return (
    <DashboardCountStatsItem
      title="Business"
      icon={<BusinessIcon />}
      color={theme.palette.primary.light}
      loading={businessLoading}
      counters={[
        {
          label: 'Registered business',
          count: formatDecimalNumbers(businessesCount),
        },
        {
          label: 'Active supporters',
          count: formatDecimalNumbers(supportersCount),
        },
      ]}
    >
      <Container>
        <ZipCode
          value={zip}
          onChange={e => setZipFilter(prev => ({ ...prev, zip: e.target.value }))}
        />

        <SelectMenu
          label={distance}
          value={distance}
          onChange={distance => setZipFilter(prev => ({ ...prev, distance }))}
          options={Object.values(DISTANCE_FILTER_IN_MILES)}
          disabled={!zip}
        />
      </Container>
    </DashboardCountStatsItem>
  );
}
