import { Fragment, useCallback, useEffect, useMemo } from 'react';
import { css } from '@emotion/react';
import { BoxProps, Paper, TableContainer, Table, TableBody } from '@material-ui/core';
import { Checkbox, IconButton, Typography } from '@mui/material';
import { MoreVert } from '@material-ui/icons';

import {
  HeadlinesType,
  SpreadsheetDataType,
  SpreadsheetCellActions,
  useSpreadsheetContext,
  SpreadsheetRow,
  SpreadsheetToolbar,
  SpreadsheetHeader,
  SpreadsheetPagination,
  SpreadsheetToolbarOptions,
  SpreadsheetActionsPopover,
} from 'shared/features/spreadsheet';

import { SpreadsheetPreloader } from './SpreadsheetPreloader';
import { SpreadsheetEmpty } from './SpreadsheetEmpty';

const paperCSS = css`
  width: 100%;
  overflow: hidden;
`;

const tableContainerCSS = css``;

export type SpreadsheetProps<T extends HeadlinesType> = {
  headlines: T;
  data: SpreadsheetDataType<T>[];
  cellActions?: SpreadsheetCellActions;
  containerProps?: BoxProps;
  itemsCount: number;
  loading?: boolean;
  toolbarOptions?: SpreadsheetToolbarOptions;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
};

export const Spreadsheet = <T extends HeadlinesType>({
  headlines,
  data,
  cellActions,
  containerProps,
  loading,
  toolbarOptions,
  itemsCount = data.length || 0,
  toolbarHeader,
  textToolbarHeader,
}: SpreadsheetProps<T>) => {
  const { page, pageSize, selected, setSelected, setLoading } = useSpreadsheetContext();

  const withCheckboxes = toolbarOptions?.withCheckboxes ?? true;

  useEffect(() => {
    setLoading(loading ?? false);
  }, [loading]);

  const columns = useMemo(() => {
    const columns: HeadlinesType = [...headlines];

    if (withCheckboxes) {
      columns.unshift({
        id: 'checkbox',
        name: 'checkbox',
        label: '',
        css: css`
          min-width: 5rem;
        `,

        render(_, data) {
          const isSelected = selected.some(curr => curr === data.id);

          return <Checkbox color="secondary" checked={isSelected} onChange={onSelect(data.id)} />;
        },
      });
    }

    if (toolbarOptions?.withIndex) {
      columns.unshift({
        id: 'index',
        name: 'index',
        label: '',
        css: css`
          min-width: 5rem;
        `,

        render(_value, _data, index) {
          const number = index + 1 + page * pageSize;
          return <Typography>#{number}</Typography>;
        },
      });
    }

    if (cellActions) {
      columns.push({
        id: 'actions',
        name: 'actions',
        label: '',
        css: css`
          min-width: 5rem;
        `,

        render(_, row) {
          return (
            <SpreadsheetActionsPopover
              cellActions={cellActions}
              row={row}
              target={
                <IconButton style={{ padding: 4 }}>
                  <MoreVert />
                </IconButton>
              }
            />
          );
        },
      });
    }

    return columns as unknown as T;
  }, [withCheckboxes, toolbarOptions, headlines, cellActions]);

  const onSelect = useCallback(
    (id: string) => () => {
      setSelected(selection => {
        const isSelected = selection.includes(id);

        if (isSelected) {
          return selection.filter(selectedId => selectedId !== id);
        }

        return [...selection, id];
      });
    },
    [setSelected],
  );

  const onSelectAll = useCallback(() => {
    setSelected(prev => {
      const selectedPage = data.filter(row => prev.includes(row.id));

      if (selectedPage.length === pageSize) {
        return [];
      }

      return selected.concat(data.map(row => row.id));
    });
  }, [data, pageSize, setSelected]);

  const onClickMainAction = useCallback(() => {
    const onClick = toolbarOptions?.mainToolbarAction?.onClick;

    onClick?.(selected ?? []);
  }, [selected, toolbarOptions]);

  const tableContent = useMemo(() => {
    if (loading) {
      return <SpreadsheetPreloader headlines={columns} />;
    }

    if (data.length === 0) {
      return <SpreadsheetEmpty headlines={columns} />;
    }

    return (
      <Fragment>
        {data.map((content, index) => (
          <SpreadsheetRow key={content.id} index={index} data={content} headlines={columns} />
        ))}
      </Fragment>
    );
  }, [
    data,
    columns,
    loading,
    onSelect,
    page,
    pageSize,
    selected,
    withCheckboxes,
    toolbarOptions?.withIndex,
  ]);

  return (
    <Paper css={paperCSS}>
      <SpreadsheetToolbar
        itemsCount={itemsCount}
        toolbarOptions={toolbarOptions}
        onSelectAll={onSelectAll}
        onClickMainAction={onClickMainAction}
        toolbarHeader={toolbarHeader}
        textToolbarHeader={textToolbarHeader}
      />

      <TableContainer css={tableContainerCSS} {...containerProps}>
        <Table stickyHeader aria-label="sticky header">
          <SpreadsheetHeader
            headlines={headlines}
            withActions={!!cellActions?.length}
            withCheckbox={withCheckboxes}
            withIndex={!!toolbarOptions?.withIndex}
          />

          <TableBody>{tableContent}</TableBody>
        </Table>
      </TableContainer>

      {toolbarOptions?.withPerPage && <SpreadsheetPagination itemsCount={itemsCount} />}
    </Paper>
  );
};
