import { APP_URL } from 'shared/constants';
import { GetRoutesFn, AdminRoute } from 'shared/routes/';

import { ContentCurationPage } from './pages/ContentCurationPage';

export const getContentCurationRoutes: GetRoutesFn = () => [
  {
    routeComponent: AdminRoute,
    path: APP_URL.admin.contentCuration.index,
    exact: false,
    component: ContentCurationPage,
  },
];
