import { useLayoutEffect, useState } from 'react';
import { useToast } from './useToast';

const PREVENT_MESSAGE = `Please do not close this tab while process is in progress.`;

export interface PreventBrowserClose {
  /**
   * Prevents the browser from closing, optionally displaying a message.
   * @param message - The message to display when attempting to close the browser.
   */
  prevent(message?: string): void;

  /**
   * Indicates that the browser is ready and can be closed without prevention.
   */
  allow(): void;
}

/**
 * A custom hook that prevents the browser from closing or refreshing the page.
 * It displays a warning message to the user when they attempt to close or refresh the page.
 *
 * @example
 * const { prevent, ready } = usePreventBrowserClose();
 *
 * // To enable prevention with a custom message
 * prevent("Are you sure you want to leave?");
 *
 * // To disable prevention
 * ready();
 */
export function usePreventBrowserClose(): PreventBrowserClose {
  const [holding, setHolding] = useState(false);
  const [message, setMessage] = useState(PREVENT_MESSAGE);

  const { showWarning } = useToast();

  function preventClose(event: BeforeUnloadEvent) {
    event.preventDefault();
    event.returnValue = message;

    showWarning(message);
    return message;
  }

  const prevent = (message?: string) => {
    if (message) {
      setMessage(message);
    }

    setHolding(true);
  };

  const allow = () => {
    setHolding(false);
  };

  useLayoutEffect(() => {
    if (!holding) {
      return;
    }

    window.addEventListener('beforeunload', preventClose);

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, [holding]);

  return {
    prevent,
    allow,
  };
}
