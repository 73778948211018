import { css, Theme } from '@emotion/react';
import { IconButton, Typography } from '@material-ui/core';
import { Edit, School } from '@material-ui/icons';
import { Fragment, useState } from 'react';
import { FileValue } from '@8base-react/file-input';

import { processFilestackUrlSrcSet } from '@jebel/utils';

import { AvatarImageInput, Box } from 'shared/components/ui';
import { useSchoolConfiguration, useToast } from 'shared/hooks';
import { useSchoolConfigurationUpdate } from 'shared/hooks/useSchoolConfigurationUpdate';

import { EDIT_SETTINGS_TYPE, EditSettingsModal } from './EditSettingsModal';

const AVATAR_SIZE = 90;

const containerCss = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem 2.5rem;
  border-bottom: 1px solid ${theme.palette.divider};
`;

const avatarCss = css`
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
`;

const titleContainerCSS = css`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const siteNameCss = (theme: Theme) => css`
  font-weight: ${theme.typography.subtitle5.fontWeight};
  font-size: ${theme.typography.subtitle5.fontSize};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const SettingsSidebarProfile = () => {
  const [editNameModal, setEditNameModal] = useState(false);
  const [editFullNameModal, setEditFullNameModal] = useState(false);
  const { configuration, loading } = useSchoolConfiguration();
  const { showSuccess } = useToast();

  const { mutate: updateSchoolConfiguration, loading: updatingSchool } =
    useSchoolConfigurationUpdate();

  const handleChangeLogo = async (logo: FileValue | null) => {
    await updateSchoolConfiguration({
      images: { logo },
    });
  };

  const handleChangeName = async (name: string) => {
    setEditNameModal(false);
    await updateSchoolConfiguration({ name });

    showSuccess('School name updated successfully.');
  };

  const handleChangeFullName = async (fullName: string) => {
    setEditFullNameModal(false);

    await updateSchoolConfiguration({ fullName });
    showSuccess('School name updated successfully.');
  };

  return (
    <Fragment>
      <Box css={containerCss}>
        <AvatarImageInput
          loading={loading || updatingSchool}
          src={configuration?.images?.logo?.downloadUrl || ''}
          srcSet={processFilestackUrlSrcSet(configuration?.images?.logo?.downloadUrl || '', {
            compress: true,
            resize: {
              width: AVATAR_SIZE,
              height: AVATAR_SIZE,
            },
          })}
          css={avatarCss}
          onChange={handleChangeLogo}
        >
          <School />
        </AvatarImageInput>

        <Box css={titleContainerCSS}>
          <Typography
            css={siteNameCss}
            variant="inherit"
            align="inherit"
            color="initial"
            paragraph={false}
          >
            {configuration?.name}
          </Typography>

          <IconButton size="small" onClick={() => setEditNameModal(true)}>
            <Edit color="inherit" fontSize="inherit" />
          </IconButton>
        </Box>

        {configuration?.fullName && (
          <Box css={titleContainerCSS}>
            <Typography variant="body2" color="textSecondary" paragraph={false}>
              {configuration?.fullName}
            </Typography>

            <IconButton size="small" onClick={() => setEditFullNameModal(true)}>
              <Edit color="inherit" fontSize="inherit" />
            </IconButton>
          </Box>
        )}
      </Box>

      <EditSettingsModal
        isOpen={editNameModal}
        type={EDIT_SETTINGS_TYPE.NAME}
        value={configuration?.name || ''}
        onClose={() => setEditNameModal(false)}
        onSave={handleChangeName}
      />

      <EditSettingsModal
        isOpen={editFullNameModal}
        type={EDIT_SETTINGS_TYPE.FULL_NAME}
        value={configuration?.fullName || ''}
        onClose={() => setEditFullNameModal(false)}
        onSave={handleChangeFullName}
      />
    </Fragment>
  );
};
