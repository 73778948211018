import { Fragment, useMemo } from 'react';
import { TableCell, TableRow } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

import { HeadlinesType, useSpreadsheetContext } from 'shared/features/spreadsheet';

interface SpreadsheetPreloaderProps {
  headlines: HeadlinesType;
}

export function SpreadsheetPreloader(props: SpreadsheetPreloaderProps) {
  const { pageSize } = useSpreadsheetContext();

  const rows = useMemo(() => {
    return Array.from({ length: pageSize }, (_, index) => index);
  }, [pageSize]);

  return (
    <Fragment>
      {rows.map(index => (
        <SpreadsheetPreloaderRow key={index} {...props} />
      ))}
    </Fragment>
  );
}

function SpreadsheetPreloaderRow({ headlines }: SpreadsheetPreloaderProps) {
  return (
    <TableRow>
      {headlines.map(headline => (
        <TableCell key={headline.id} css={headline.css}>
          <Skeleton width="90%" style={{ minWidth: '2rem' }} />
        </TableCell>
      ))}
    </TableRow>
  );
}
