import React, { Fragment } from 'react';
import { css, Theme } from '@emotion/react';
import { Box, Paper, Divider, MenuItem } from '@material-ui/core';

import { parseDate } from '@jebel/utils';
import { MIN_PLATFORM_DATE } from '@jebel/constants';

import { Typography, Select, Button, Icon, TextField, DatePicker } from 'shared/components/ui';
import { useSearchDateFilterContext } from 'shared/features/search';
import { SELECT_OPTION_ALL } from 'shared/constants';
import { DateRange } from 'shared/hooks';

import { POST_CATEGORIES, REPORT_TYPE, REVENUE_CATEGORY } from '../constants';
import { LocalFilterType } from '../types';

const leftSidebarPaperCss = css`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1rem;
`;

const playIconCss = (theme: Theme) => css`
  margin-right: ${theme.spacing(0.5)}px;
`;

const runReportCss = (theme: Theme) => css`
  font-weight: 600;
  font-size: ${theme.typography.fontSize}px;
`;

export const ReportsLeftSidebar = ({
  setReportsFilter,
  setPage,
}: {
  setReportsFilter: React.Dispatch<LocalFilterType>;
  setPage: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { filter: dateRange, setFilter: setDateRange } = useSearchDateFilterContext<DateRange>();

  const [localFilter, setLocalFilter] = React.useState<LocalFilterType>({
    reportType: REPORT_TYPE.contentEngagementReport,
    postCategory: SELECT_OPTION_ALL,
    withReactions: false,
    withComments: false,
    revenueCategory: REVENUE_CATEGORY.all,
    trendingWordsCount: '',
    trendingWord: '',
    wasReportRan: false,
  });

  const {
    switchReportType,
    switchPostCategory,
    switchStartDate,
    switchEndDate,
    switchRevenueCategory,
    switchTrendingWordsCount,
    switchTrendingWord,
  } = React.useMemo(
    () => ({
      switchReportType: event => setLocalFilter({ ...localFilter, reportType: event.target.value }),
      switchPostCategory: event =>
        setLocalFilter({ ...localFilter, postCategory: event.target.value }),
      switchStartDate: event => setDateRange(prev => ({ ...prev, startDate: parseDate(event) })),
      switchEndDate: event => setDateRange(prev => ({ ...prev, endDate: parseDate(event) })),
      switchRevenueCategory: event =>
        setLocalFilter({ ...localFilter, revenueCategory: event.target.value }),
      switchTrendingWordsCount: event =>
        setLocalFilter({ ...localFilter, trendingWordsCount: event.target.value }),
      switchTrendingWord: event =>
        setLocalFilter({ ...localFilter, trendingWord: event.target.value }),
    }),
    [localFilter, setLocalFilter],
  );

  const onRunReport = React.useCallback(() => {
    setReportsFilter({ ...localFilter, wasReportRan: true });
    setPage(1);
  }, [localFilter, setReportsFilter, setPage]);

  return (
    <Box>
      <Paper css={leftSidebarPaperCss}>
        <Box display="grid" gridTemplateRows="auto">
          <Box mx={2} my="1rem">
            <Typography color="textSecondary" css={runReportCss}>
              RUN NEW REPORT
            </Typography>
          </Box>
          <Divider />
          <Box display="grid" m={2} gridTemplateColumns="auto">
            <Select
              variant="outlined"
              label="REPORT TYPE"
              value={localFilter.reportType}
              onChange={switchReportType}
            >
              {Object.values(REPORT_TYPE).map(item => (
                <MenuItem key={item} value={item}>
                  {item}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box pl={2} mb={1}>
            <Typography variant="body1" color="textSecondary">
              Filters (Optional)
            </Typography>
          </Box>

          <Box display="grid" m={2} gridGap="1rem">
            <DatePicker
              label="Date from"
              value={dateRange?.startDate}
              onChange={switchStartDate}
              InputProps={{
                disableUnderline: true,
              }}
              allowKeyboardControl
              inputVariant="outlined"
              variant="inline"
              minDate={MIN_PLATFORM_DATE}
              maxDate={dateRange?.endDate ?? undefined}
              maxDateMessage="Start date cant be later than the end date."
              disableFuture
            />

            <DatePicker
              label="Date to"
              value={dateRange?.endDate}
              onChange={switchEndDate}
              InputProps={{
                disableUnderline: true,
              }}
              allowKeyboardControl
              inputVariant="outlined"
              variant="inline"
              disableFuture
              minDate={dateRange?.startDate ?? undefined}
              minDateMessage="End date cant be earlier than the start date."
            />

            {localFilter.reportType === REPORT_TYPE.contentEngagementReport && (
              <Select
                variant="outlined"
                label="POST CATEGORY"
                onChange={switchPostCategory}
                value={localFilter.postCategory}
              >
                {Object.values(POST_CATEGORIES).map(item => (
                  <MenuItem key={item.value} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            )}

            {localFilter.reportType === REPORT_TYPE.revenueReport && (
              <Select
                variant="outlined"
                label="REVENUE CATEGORY"
                onChange={switchRevenueCategory}
                value={localFilter.revenueCategory}
              >
                {Object.values(REVENUE_CATEGORY).map(item => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </Select>
            )}

            {localFilter.reportType === REPORT_TYPE.trendingSearchesReport && (
              <Fragment>
                <TextField
                  value={localFilter?.trendingWordsCount}
                  onChange={switchTrendingWordsCount}
                  variant="outlined"
                  type="number"
                  label="Minimum Search Count"
                  InputProps={{
                    inputProps: { min: 1 },
                  }}
                />

                <TextField
                  value={localFilter?.trendingWord}
                  onChange={switchTrendingWord}
                  variant="outlined"
                  label="Search term"
                />
              </Fragment>
            )}
          </Box>
        </Box>

        <Box display="grid" gridTemplateColumns="auto" p={2}>
          <Button
            color="primary"
            variant="contained"
            onClick={onRunReport}
            startIcon={<Icon name="PlayArrow" css={playIconCss} />}
            disableElevation
          >
            RUN REPORT
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};
