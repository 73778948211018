import { UserSchoolMembershipStatus, UserStatus } from '@jebel/constants';
import { createFilterBuilder } from '@jebel/utils';

import {
  SchoolConfigurationKeyFilter,
  UserFilter,
  UserSchoolMembershipFilter,
} from 'shared/graphql';

interface Options {
  initial?: UserFilter;

  search?: string;

  /**
   * Filter by users connected to the given school.
   * @ticket https://github.com/jebelapp/jebel/issues/1564
   */
  school?: SchoolConfigurationKeyFilter;

  zip?: string;

  /**
   * Filter the users by "status".
   * @default true
   */
  onlyActive?: boolean;
}

export function createUserFilter(options?: Options) {
  const filter = createFilterBuilder<UserFilter>(options?.initial);

  const membership = createFilterBuilder<UserSchoolMembershipFilter>();

  const onlyActive = options?.onlyActive ?? true;

  if (onlyActive) {
    filter.and({ status: { equals: UserStatus.Active } });
    membership.and({ status: { equals: UserSchoolMembershipStatus.Active } });
  }

  if (options?.search) {
    const searchFilter = createFilterBuilder<UserFilter>();

    searchFilter.or({ email: { contains: options.search } });
    searchFilter.or({ firstName: { contains: options.search } });
    searchFilter.or({ lastName: { contains: options.search } });
    searchFilter.or({ fullName: { starts_with: options.search } });
    searchFilter.or({ currentIndustry: { contains: options.search } });
    searchFilter.or({ description: { contains: options.search } });

    filter.and(searchFilter);
  }

  if (options?.zip) {
    filter.and({
      userPreferences: {
        address: {
          zip: { contains: options.zip },
        },
      },
    });
  }

  if (options?.school) {
    // https://github.com/jebelapp/jebel/issues/1564
    membership.and({ school: { id: { equals: options.school.id } } });
    filter.and({ schoolMemberships: { some: membership.build() } });
  }

  return filter.build();
}
