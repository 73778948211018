import { css } from '@emotion/react';
import { MenuItem } from '@material-ui/core';
import { FilterAlt as IconFilter, MyLocation as IconLocation } from '@mui/icons-material';
import { useFormikContext } from 'formik';

import { DISTANCE_FILTER_IN_MILES } from '@jebel/constants';

import { Button, Typography, FormTextField, FormSelect } from 'shared/components/ui';
import { useCurrentLocationZip, useResponsive, useToast } from 'shared/hooks';
import { isOptionalValidZipCode } from 'shared/utils/form';
import { SearchContextZipFilter } from 'shared/features/search';

import { Container, Content, Header } from './EventsFilterForm.styles';

const zipCodeFieldCSS = (isMobile: boolean) => css`
  ${!isMobile && `grid-area: b;`}
`;

export function EventsFilterForm() {
  const { showError } = useToast();
  const { isMobile } = useResponsive();
  const { values, setFieldValue } = useFormikContext<SearchContextZipFilter>();
  const { loading: searchingZip, onSearchZip } = useCurrentLocationZip();

  const handleUsePosition = async () => {
    navigator.geolocation.getCurrentPosition(async position => {
      try {
        const lng = position?.coords.longitude;
        const lat = position?.coords.latitude;

        const response = await onSearchZip(lng.toString(), lat.toString());
        const zip = response.data?.currentLocationZipRequest.zip;

        if (zip) {
          setFieldValue('zip', zip);
        }
      } catch (err) {
        showError('Something went wrong getting your zip-code, please try again.');

        if (err instanceof Error) {
          showError(err.message);
        }
      }
    });
  };

  return (
    <Container>
      {!isMobile && (
        <Header>
          <IconFilter />

          <Typography variant="subtitle5">Filter by</Typography>
        </Header>
      )}

      <Content>
        <FormTextField
          fieldProps={{
            name: 'zip',
            validate: isOptionalValidZipCode,
          }}
          inputProps={{
            placeholder: 'ZIP Code',
            variant: 'outlined',
            css: zipCodeFieldCSS(isMobile),
          }}
        />

        <FormSelect
          fieldProps={{
            name: 'distance',
          }}
          selectProps={{
            label: 'Distance',
            variant: 'outlined',
            disabled: !values.zip,
            children: Object.values(DISTANCE_FILTER_IN_MILES).map(distance => (
              <MenuItem key={distance.toString()} value={distance.toString()}>
                {distance.toString()}
              </MenuItem>
            )),
          }}
        />

        <Button
          type="button"
          color="secondary"
          onClick={handleUsePosition}
          startIcon={<IconLocation color="inherit" />}
          loading={searchingZip}
        >
          Use current location
        </Button>
      </Content>
    </Container>
  );
}
