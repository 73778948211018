import { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { Theme, css } from '@emotion/react';

import { formatSQLDate, humanizeText } from '@jebel/utils';

import { LoadingOverlay } from 'shared/components/ui';
import { BarChart, BarChartData, LinearChart, LinearTimeChartData } from 'shared/components/charts';
import { CardLayout } from 'shared/components/layouts';
import { useSearchDateFilterContext } from 'shared/features/search';
import { DateRange } from 'shared/hooks';

import { DashboardChartTemplateTop } from './DashboardChartTemplateTop';
import { useDashboardMembersStats, useMembersCountByAffiliation } from '../hooks';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const sectionContainerCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const paperContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  flex: 1;
`;

export function DashboardMembersCharts() {
  const { filter: dateFilter } = useSearchDateFilterContext<DateRange>();

  const { data: cumulativeMembers, loading: cumulativeMembersLoading } = useDashboardMembersStats({
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
  });

  const { data: membersByAffiliation, loading: membersByAffiliationLoading } =
    useMembersCountByAffiliation({
      startDate: dateFilter.startDate,
      endDate: dateFilter.endDate,
    });

  const cumulativeMembersChart = useMemo(() => {
    return cumulativeMembers.map<LinearTimeChartData>(item => {
      const count = item.totalMembers ?? 0;
      const timestamp = formatSQLDate(item.createdAt);

      return {
        timestamp,
        value: count,
        legend: `Members at ${timestamp}: <b>${count}</b>`,
      };
    });
  }, [cumulativeMembers]);

  const membersByAffiliationChart = useMemo(() => {
    return membersByAffiliation.map<BarChartData>(item => {
      return {
        category: humanizeText(item.affiliation),
        value: item.count,
      };
    });
  }, [membersByAffiliation]);

  return (
    <Box css={containerCSS}>
      <Box display="flex" justifyContent="start" gridGap="1rem" alignItems="baseline">
        <Typography variant="h5">Members</Typography>
      </Box>

      <Box css={sectionContainerCSS}>
        <CardLayout css={paperContentCSS}>
          <DashboardChartTemplateTop title="Cumulative Members" />

          <LoadingOverlay
            loading={cumulativeMembersLoading}
            overlay={<Skeleton variant="rectangular" height={300} />}
          >
            <LinearChart type="time" data={cumulativeMembersChart} />
          </LoadingOverlay>
        </CardLayout>

        <CardLayout css={paperContentCSS}>
          <DashboardChartTemplateTop title="Members by Type" />

          <LoadingOverlay
            loading={membersByAffiliationLoading}
            overlay={<Skeleton variant="rectangular" height={300} />}
          >
            <BarChart data={membersByAffiliationChart} />
          </LoadingOverlay>
        </CardLayout>
      </Box>
    </Box>
  );
}
