import { formatISO8601Date } from '@jebel/utils';
import { SearchContextZipFilter } from 'shared/features/search';
import { useSchoolAdministrationBusinessCountQuery } from 'shared/graphql';
import { DateRange, useSchoolConfiguration } from 'shared/hooks';

type BusinessesCountHookProps = {
  zipFilter: SearchContextZipFilter | null;
  dateFilter?: DateRange;
};

export const useBusinessesCount = ({ zipFilter, dateFilter }: BusinessesCountHookProps) => {
  const { data: school } = useSchoolConfiguration();

  const startPointZip = zipFilter?.zip ?? '';
  const radius = zipFilter?.distance?.split(' ')[0] ?? '';

  const startDate = formatISO8601Date(dateFilter?.startDate);
  const endDate = formatISO8601Date(dateFilter?.endDate);

  const { loading, data, ...rest } = useSchoolAdministrationBusinessCountQuery({
    skip: !school,
    variables: {
      filter: {
        AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
      },
      supporterFilter: {
        status: { equals: 'active' },

        schoolSupporter: {
          some: {
            status: { equals: 'active' },
            school: {
              // Filter by the current school.
              // https://github.com/jebelapp/jebel/issues/1675
              id: { equals: school?.id },
            },
          },
        },

        AND: [{ createdAt: { gte: startDate } }, { createdAt: { lte: endDate } }],
      },
      radius,
      startPointZip,
    },
  });

  const businessesCount = data?.businesses?.count ?? 0;
  const supportersCount = data?.supporters?.count ?? 0;

  return {
    businessesCount,
    supportersCount,
    loading: loading && !data,
    ...rest,
  };
};
