import { useMemo } from 'react';
import { css } from '@emotion/react';

import { createFilterBuilder, formatTableDate } from '@jebel/utils';

import { Icon, Typography } from 'shared/components/ui';
import { DISCOUNT_CATEGORIES } from 'shared/constants/discounts';
import { useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, SpreadsheetCellActions } from 'shared/features/spreadsheet';
import { useSpreadsheetContext } from 'shared/features/spreadsheet/providers';
import {
  AdminDiscountFragment,
  AdminDiscountsSpreadsheetListQuery,
  AdminDiscountsSpreadsheetListQueryVariables,
  DiscountFilter,
} from 'shared/graphql';
import { useDownloadLazyQueryCSV, useSchoolConfiguration } from 'shared/hooks';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import {
  COLUMBUS_HIGH_SCHOOL,
  DiscountReportHeaders,
  DiscountsSpreadsheetFilters,
  DiscountsSpreadsheetHeader,
} from '../constants';
import { ADMIN_DISCOUNTS_LIST_QUERY } from '../query';

const discountTableNameCSS = css`
  width: 100%;
  text-align: left;
  font-size: 14px;
`;

interface Props {
  onSelectDiscount: (discount?: AdminDiscountFragment) => void;
}

export function DiscountsSpreadsheet({ onSelectDiscount }: Props) {
  const { data: school } = useSchoolConfiguration();
  const { queryParams, selected } =
    useSpreadsheetContext<AdminDiscountsSpreadsheetListQueryVariables>();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<DiscountFilter>(queryParams.filter);

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1683
      filter.and({ schools: { some: { id: { equals: school.id } } } });
    }

    return filter.build();
  }, [school]);

  const {
    tableData,
    tableLoading: isLoading,
    queryVariables,
  } = useSpreadsheetSearch<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >({
    query: ADMIN_DISCOUNTS_LIST_QUERY,
    searchingFields: ['title', 'category', 'organization.name'],
    queryVariables: { ...queryParams, filter },
  });

  const discounts = useMemo(() => tableData?.discounts?.items ?? [], [tableData]);
  const discountsCount = tableData?.discounts?.count ?? 0;

  const downloadFilter = useMemo(() => {
    const filter = createFilterBuilder<DiscountFilter>(queryVariables.filter);

    if (selected.length > 0) {
      // Exclude the others by filter with selected IDs.
      return { id: { in: selected } };
    }

    return filter.build();
  }, [queryVariables, selected]);

  const [downloadCSV] = useDownloadLazyQueryCSV<
    AdminDiscountsSpreadsheetListQuery,
    AdminDiscountsSpreadsheetListQueryVariables
  >(ADMIN_DISCOUNTS_LIST_QUERY, {
    filename: getFileNameWithTimestamp('Discounts.csv'),
    transform: transformExport,

    variables: {
      filter: downloadFilter,
    },
  });

  const spreadsheetActions: SpreadsheetCellActions = [
    {
      id: 'View Details',
      title: 'View Details',
      icon: <Icon name="Edit" />,

      onClickAction(id: string) {
        const discount = discounts.find(discount => discount.id === id);
        onSelectDiscount(discount);
      },
    },
  ];

  const newData = useMemo(() => {
    return discounts.map(discount => {
      const category = DISCOUNT_CATEGORIES.find(category => category.key === discount.category);
      const createdBy = discount.organization?.name ?? school?.fullName ?? '-';
      const redemptions = discount.redemptions?.count ?? 0;

      return {
        id: discount.id as string,
        name: (
          <Typography css={discountTableNameCSS} onClick={() => onSelectDiscount(discount)}>
            {discount.title}
          </Typography>
        ),
        category: category?.value,
        createdBy: `${createdBy}`,
        expiredDate: formatTableDate(discount.expirationDate),
        createdAt: formatTableDate(discount.createdAt),
        redeemCount: `${redemptions} times`,
      };
    });
  }, [discounts, school?.fullName, onSelectDiscount]);

  return (
    <Spreadsheet
      data={newData}
      headlines={DiscountsSpreadsheetHeader}
      cellActions={spreadsheetActions}
      itemsCount={discountsCount}
      loading={isLoading}
      toolbarOptions={{
        filters: DiscountsSpreadsheetFilters,
        withPerPage: true,
        withDownload: true,
        downloadHandler: downloadCSV,
        rawData: discounts,
      }}
    />
  );
}

function transformExport(response: AdminDiscountsSpreadsheetListQuery) {
  const discounts = response.discounts.items ?? [];

  return discounts.map(discount => {
    const redemptions = discount.redemptions?.count ?? 0;

    return {
      [DiscountReportHeaders.name]: discount.title,
      [DiscountReportHeaders.category]: discount.category,
      [DiscountReportHeaders.createdBy]: discount.organization?.name ?? COLUMBUS_HIGH_SCHOOL,
      [DiscountReportHeaders.expiresOn]: formatTableDate(discount.expirationDate),
      [DiscountReportHeaders.redeemed]: `${redemptions} times`,
      [DiscountReportHeaders.createdOn]: formatTableDate(discount.createdAt),
      [DiscountReportHeaders.status]: discount.status,
    };
  });
}
