import { SchoolMemberFragment } from 'shared/graphql';

import { JobSeekersListItem } from './JobSeekersListItem';
import { Fragment } from 'react';

interface Props {
  usersList: SchoolMemberFragment[];
}

export function JobSeekersList(props: Props) {
  return (
    <Fragment>
      {props.usersList?.map(item => (
        <JobSeekersListItem key={item?.id} data={item} />
      ))}
    </Fragment>
  );
}
