import { TableCell } from '@material-ui/core';

import { Typography } from 'shared/components/ui';
import {
  HeadlineType,
  SpreadsheetCellType,
  SpreadsheetIdentifiedDataType,
} from 'shared/features/spreadsheet';
import { isFn, isStr } from '@jebel/utils';

interface Props {
  /** The headline of the cell. */
  headline: HeadlineType;
  /** The index of the row. */
  index: number;
  /** The data of the cell. */
  data: SpreadsheetIdentifiedDataType;
  /** The value on the given cell. */
  value: SpreadsheetCellType;
}

export function SpreadsheetCell({ index, value, data, headline }: Props) {
  if (isFn(headline.render)) {
    return <TableCell>{headline.render(value, data, index)}</TableCell>;
  }

  if (isStr(value)) {
    return (
      <TableCell align="left" css={headline.css}>
        <Typography variant="body2" withEllipsis>
          {value}
        </Typography>
      </TableCell>
    );
  }

  return <TableCell css={headline.css}>{value}</TableCell>;
}
