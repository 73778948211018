import { useMemo } from 'react';

import { FROM_STRIPE_CENTS, createFilterBuilder, formatMoney } from '@jebel/utils';
import { BillingType } from '@jebel/constants';

import { useSearchDateFilterContext, useSpreadsheetSearch } from 'shared/features/search';
import { Spreadsheet, useSpreadsheetContext } from 'shared/features/spreadsheet';
import {
  DebtOperationHistoryFilter,
  RevenueReportQuery,
  RevenueReportQueryVariables,
} from 'shared/graphql';
import {
  DateRange,
  useDownloadLazyQueryCSV,
  useDownloadLazyQueryPDF,
  useSchoolConfiguration,
} from 'shared/hooks';
import { getFileNameWithTimestamp } from 'shared/utils/file';

import {
  RevenueReportHeaders,
  RevenueReportsSpreadsheetHeadlines,
  RevenueReportsSpreadsheetHeadlinesEvent,
} from '../constants';
import { REVENUE_REPORT_QUERY } from '../queries';

interface RevenueReportsSpreadsheetProps {
  category: string;
  toolbarHeader?: JSX.Element;
  textToolbarHeader?: string;
}

const REVENUE_TYPE = {
  All: undefined,
  'Supporter Fees': BillingType.SchoolSupport,
  'Targeted Ads': BillingType.Advertising,
  'Business Search': undefined,
  Offers: BillingType.SpecialOffer,
  'Event Sponsorship': undefined,
};

export function RevenueReportsSpreadsheet({
  category,
  toolbarHeader,
  textToolbarHeader,
}: RevenueReportsSpreadsheetProps) {
  const { data: school } = useSchoolConfiguration();
  const { filter: dateRange } = useSearchDateFilterContext<DateRange>();
  const { queryParams } = useSpreadsheetContext();

  const debtFilter = useMemo(() => {
    const filter = createFilterBuilder<DebtOperationHistoryFilter>({
      operation: { equals: 'deposit' },
    });

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1685
      filter.and({ schools: { some: { id: { equals: school.id } } } });
    }

    if (category) {
      filter.and({ billingType: { equals: REVENUE_TYPE[category] } });
    }

    if (dateRange?.startDate) {
      filter.and({ dateTimeOperation: { gte: dateRange.startDate } });
    }

    if (dateRange?.endDate) {
      filter.and({ dateTimeOperation: { lte: dateRange.endDate } });
    }

    return filter.build();
  }, [dateRange, category, school]);

  const { tableData, tableLoading, queryVariables } = useSpreadsheetSearch<
    RevenueReportQuery,
    RevenueReportQueryVariables
  >({
    skip: !school?.id,
    query: REVENUE_REPORT_QUERY,
    searchingFields: ['name'],
    queryVariables: { ...queryParams, debtFilter },
  });

  const count = tableData?.organizations?.count ?? 0;

  const data = useMemo(() => {
    return transformExport(tableData, { category });
  }, [category, tableData]);

  const [downloadCSV] = useDownloadLazyQueryCSV<RevenueReportQuery, RevenueReportQueryVariables>(
    REVENUE_REPORT_QUERY,
    {
      filename: getFileNameWithTimestamp('Revenue.csv'),
      transform(response) {
        return transformExport(response, { category });
      },

      variables: {
        filter: queryVariables.filter,
      },
    },
  );

  const [downloadPDF] = useDownloadLazyQueryPDF<RevenueReportQuery, RevenueReportQueryVariables>(
    REVENUE_REPORT_QUERY,
    {
      filename: getFileNameWithTimestamp('Revenue.pdf'),
      displayName: 'Revenue Report',

      transform(response) {
        return transformExport(response, { category });
      },

      variables: {
        filter: queryVariables.filter,
      },
    },
  );

  return (
    <Spreadsheet
      data={data}
      headlines={
        category === 'Event Sponsorship'
          ? RevenueReportsSpreadsheetHeadlinesEvent
          : RevenueReportsSpreadsheetHeadlines
      }
      toolbarOptions={{
        withDownload: true,
        withPDFDownload: true,
        withPerPage: true,
        downloadHandler: downloadCSV,
        downloadPDFHandler: downloadPDF,
        rawData: tableData?.organizations?.items ?? [],
      }}
      itemsCount={count}
      loading={tableLoading}
      toolbarHeader={toolbarHeader}
      textToolbarHeader={textToolbarHeader}
    />
  );
}

function transformExport(response: RevenueReportQuery | undefined, context: { category: string }) {
  const organizations = response?.organizations.items ?? [];

  return organizations.map(organization => {
    const sponsorsRequests = organization.sponsorRequests?.count ?? 0;

    const report = {
      [RevenueReportHeaders.id]: organization.id as string,
      [RevenueReportHeaders.advertiser]: organization.name,
      [RevenueReportHeaders.sponsorsRequests]: sponsorsRequests,
      [RevenueReportHeaders.revenueGenerated]: formatMoney(0),
    };

    const [metrics] = organization.debt?.balance?.metrics ?? [];

    if (metrics?.revenue) {
      report[RevenueReportHeaders.revenueGenerated] = formatMoney(
        metrics.revenue * FROM_STRIPE_CENTS,
      );
    }

    return report;
  });
}
