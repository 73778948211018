import { gql } from '@apollo/client';

export const REQUEST_BUSINESS_ACCESS_MUTATION = gql`
  mutation RequestBusinessAccess(
    $email: String!
    $firstname: String!
    $lastname: String!
    $school: SchoolConfigurationKeyFilter
  ) {
    commit: requestBusinessAccess(
      email: $email
      firstname: $firstname
      lastname: $lastname
      school: $school
    ) {
      success
    }
  }
`;
