import { useMemo } from 'react';

import { useSearchContext } from 'shared/features/search';
import { getPreviewCareerSearchFilter } from 'shared/utils/search';
import { PAGE_SIZE } from 'shared/constants';
import { useCareersPageSearchQuery, useSchoolMembersReportQuery } from 'shared/graphql';
import { useSchoolConfiguration } from 'shared/hooks';

export const usePreviewSearch = (localZipQuery: string, initialRadius: string) => {
  const { data: school } = useSchoolConfiguration();
  const { searchQuery, zipQuery } = useSearchContext();

  const {
    userFilter: usersFilter,
    internshipsFilter: internshipFilter,
    jobOfferFilter: careerFilter,
  } = useMemo(() => getPreviewCareerSearchFilter(searchQuery), [searchQuery]);

  const radius = initialRadius.split(' ')[0] ?? '';

  const { data: responseCareers, loading: loadingCareers } = useCareersPageSearchQuery({
    variables: {
      careersFirst: PAGE_SIZE,
      startPointZip: zipQuery || localZipQuery,
      radius,
      careerFilter,
    },
  });

  const { data: responseInternships, loading: loadingInternships } = useCareersPageSearchQuery({
    variables: {
      careersFirst: PAGE_SIZE,
      startPointZip: zipQuery || localZipQuery,
      radius,
      careerFilter: internshipFilter,
    },
  });

  const { data: responseUsers, loading: loadingUsers } = useSchoolMembersReportQuery({
    skip: !school?.id,
    variables: {
      radius,
      school: { id: school?.id },
      startPointZip: zipQuery || localZipQuery,
      first: PAGE_SIZE,
      filter: usersFilter,
    },
  });

  const offers = responseCareers?.page?.items ?? [];
  const internships = responseInternships?.page?.items ?? [];
  const seekers = responseUsers?.members?.items ?? [];

  const loading = loadingCareers || loadingInternships || loadingUsers;

  return {
    previewUsers: seekers,
    previewJobOffers: offers,
    previewInternships: internships,
    /** @deprecated */
    previewLoading: loading,
    loading,
  };
};
