import { SORT } from '@jebel/constants';

import { RouteLayout } from 'shared/components/layouts';
import { SearchProvider } from 'shared/features/search';
import { SpreadsheetProvider } from 'shared/features/spreadsheet';

import { lazy, Suspense } from 'react';
import { DashboardPageSkeleton } from 'shared/components/ui';

const BusinessContent = lazy(() => import('../components/BusinessContent'));

const FALLBACK_SORT = { sort: { createdAt: SORT.desc }, order: SORT.desc, headcellId: 'createdAt' };

export function BusinessPage() {
  return (
    <RouteLayout
      content={
        <Suspense fallback={<DashboardPageSkeleton />}>
          <SearchProvider defaultSortOption={FALLBACK_SORT.sort}>
            <SpreadsheetProvider defaultSortOption={FALLBACK_SORT}>
              <BusinessContent />
            </SpreadsheetProvider>
          </SearchProvider>
        </Suspense>
      }
    />
  );
}
