import { Box, Typography } from '@mui/material';

import { HeadlinesType } from '../../types';

interface Props {
  headlines: HeadlinesType;
}

export function SpreadsheetEmpty({ headlines }: Props) {
  return (
    <tr>
      <td colSpan={headlines.length}>
        <Box
          sx={{ width: '100%', height: 450 }}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography color="GrayText">NO DATA</Typography>
        </Box>
      </td>
    </tr>
  );
}
