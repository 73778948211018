import { useContext } from 'react';

import { SearchContext } from './SearchContext';
import { SearchContextValue } from '../types';

/** @throws When outside of `SearchProvider`. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSearchContext<F = any, D = any>() {
  const context = useContext(SearchContext);

  if (!context) {
    throw new Error(`No "SearchContext" provided in the current context`);
  }

  return context as SearchContextValue<F, D>;
}

/** @throws When outside of `SearchProvider`. */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useSearchDateFilterContext<DateFilter = any>() {
  const { dateFilter, setDateFilter } = useSearchContext<unknown, DateFilter>();

  return {
    filter: dateFilter,
    setFilter: setDateFilter,
  };
}
