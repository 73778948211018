import { DateTime } from 'luxon';
import { Typography } from '@mui/material';
import { DatePicker } from '@material-ui/pickers';
import { css } from '@emotion/react';

import { DateRange, useCurrentUser } from 'shared/hooks';
import { useSearchDateFilterContext } from 'shared/features/search';
import { MIN_PLATFORM_DATE } from '@jebel/constants';

const headerCSS = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const filtersCSS = css`
  display: flex;
  gap: 0.5rem;
`;

const MIN_DATE = DateTime.fromSQL(MIN_PLATFORM_DATE);

export function DashboardHeader() {
  const { user } = useCurrentUser();
  const { filter, setFilter } = useSearchDateFilterContext<DateRange>();

  const handleStartDateChange = (value: DateTime | null) => {
    setFilter(prev => ({ ...prev, startDate: value }));
  };

  const handleEndDateChange = (value: DateTime | null) => {
    setFilter(prev => ({ ...prev, endDate: value }));
  };

  return (
    <header css={headerCSS}>
      <Typography variant="h5" fontWeight="medium">
        Welcome back, {user?.firstName}
      </Typography>

      <div css={filtersCSS}>
        <DatePicker
          label="From"
          value={filter.startDate}
          onChange={handleStartDateChange}
          inputVariant="outlined"
          format="MMM dd, yyyy"
          size="small"
          disableFuture
          minDate={MIN_DATE}
        />

        <DatePicker
          label="To"
          value={filter.endDate}
          onChange={handleEndDateChange}
          inputVariant="outlined"
          format="MMM dd, yyyy"
          size="small"
          disableFuture
          minDate={MIN_DATE}
        />
      </div>
    </header>
  );
}
