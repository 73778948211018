import { useState, useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Skeleton } from '@mui/material';
import { Theme, css } from '@emotion/react';

import { formatMoney, formatSQLDate } from '@jebel/utils';

import { LoadingOverlay } from 'shared/components/ui';
import { LinearChart, LinearTimeChartData } from 'shared/components/charts';
import { CardLayout } from 'shared/components/layouts';
import { useSearchDateFilterContext } from 'shared/features/search';
import { DateRange } from 'shared/hooks';

import { DashboardChartTemplateTop } from './DashboardChartTemplateTop';
import { DashboardAdvertisingSelect } from './DashboardAdvertisingSelect';
import { optionToRevenue, optionToVolume } from '../consts';
import { useDashboardAdvertisingStats } from '../hooks';

const containerCSS = css`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const sectionContainerCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;

const paperContentCSS = css`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2rem;
  flex: 1;
`;

export function DashboardAdvertisingCharts() {
  const { filter: dateFilter } = useSearchDateFilterContext<DateRange>();

  const { data, loading } = useDashboardAdvertisingStats({
    startDate: dateFilter.startDate,
    endDate: dateFilter.endDate,
  });

  const [selectedOption, setSelectedOption] = useState<string>('all');

  const advertisingChartData = useMemo(() => {
    return data.map<LinearTimeChartData>(item => {
      const amount = item[optionToVolume[selectedOption]] ?? 0;

      return {
        timestamp: formatSQLDate(item.createdAt),
        value: amount,
      };
    });
  }, [data, selectedOption]);

  const revenueChartData = useMemo(() => {
    return data.map<LinearTimeChartData>(item => {
      const amount = item[optionToRevenue[selectedOption]] ?? 0;

      return {
        timestamp: formatSQLDate(item.createdAt),
        value: amount,
      };
    });
  }, [data, selectedOption]);

  return (
    <Box css={containerCSS}>
      <Box display="flex" justifyContent="start" gridGap="1rem" alignItems="baseline">
        <Typography variant="h5">Advertising</Typography>

        <Box display="flex" gridGap="1rem">
          <DashboardAdvertisingSelect
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </Box>
      </Box>

      <Box css={sectionContainerCSS}>
        <CardLayout css={paperContentCSS}>
          <DashboardChartTemplateTop title="Volume" />

          <LoadingOverlay
            loading={loading}
            overlay={<Skeleton variant="rectangular" height={300} />}
          >
            <LinearChart type="time" data={advertisingChartData} />
          </LoadingOverlay>
        </CardLayout>

        <CardLayout css={paperContentCSS}>
          <DashboardChartTemplateTop title="Revenue" />

          <LoadingOverlay
            loading={loading}
            overlay={<Skeleton variant="rectangular" height={300} />}
          >
            <LinearChart
              type="time"
              data={revenueChartData}
              formatValue={value => formatMoney(value)}
            />
          </LoadingOverlay>
        </CardLayout>
      </Box>
    </Box>
  );
}
