import { useMemo } from 'react';
import { Person } from '@material-ui/icons';

import { MinimalUserFragment, SchoolMemberFragment } from 'shared/graphql';
import { formatUserName } from 'shared/utils/user';
import { generateUserProfileRoute } from 'shared/routes';

import { AuthorAvatar, AuthorAvatarProps } from './AuthorAvatar';

interface Props extends Omit<AuthorAvatarProps, 'name' | 'source' | 'route'> {
  user: MinimalUserFragment | SchoolMemberFragment | undefined | null;

  disabled?: boolean;

  className?: string;
}

export function UserAvatar({ user, ...props }: Props) {
  const source = user?.avatar?.downloadUrl ?? undefined;
  const name = formatUserName(user);

  const route = useMemo(() => {
    return generateUserProfileRoute(user?.id);
  }, [user]);

  return (
    <AuthorAvatar
      {...props}
      source={source}
      name={name}
      route={route}
      disabled={!user || props.disabled}
    >
      <Person fontSize="inherit" />
    </AuthorAvatar>
  );
}
