import { Fragment, useState } from 'react';
import { DateTime } from 'luxon';
import { Add } from '@material-ui/icons';
import { ApolloError } from '@apollo/client';

import { RoleName, SystemRoleName } from '@jebel/constants';

import {
  useResponsive,
  UserInvitationParsed,
  useToast,
  useUserInvitation,
  useUserInvitationCSV,
  useUpdateUserRoleAddons,
} from 'shared/hooks';
import { Button, Form, FormFieldArray } from 'shared/components/ui';
import { recordError } from 'shared/utils/record';

import type { InviteMemberFormValues } from './types';
import { InviteMemberItem } from './InviteMemberItem';
import { DialogFooter, FileInputButton } from './StyledComponents';

const FILE_TYPE = '.csv';

const INVITING_CSV_MESSAGE = `Inviting imported users from CSV, please wait until the process finishes.`;
const INVITING_CSV_MESSAGE_KEY = `INVITING_CSV`;
const ERROR_GENERIC_MESSAGE = 'Something goes wrong when creating the user, please try again.';

const createInitialValues = (): UserInvitationParsed => ({
  firstname: '',
  lastname: '',
  email: '',
  phoneNumber: '',
  affiliation: '',
  affiliationDescription: '',
  graduatingYear: '',
});

const mapGraduatingYear = (year?: number | string): string | null => {
  if (!year) return null;
  const date = typeof year === 'string' ? DateTime.fromISO(year) : DateTime.utc(year, 1, 1);
  if (!date.isValid) return null;
  return date.toISODate();
};

const initialValues: InviteMemberFormValues = {
  members: [createInitialValues()],
};

/**
 * Modal props
 */
type InviteMemberMeta = {
  businessId: string;
};
type Props = {
  onClose: () => void;
  onSuccessfulSubmit?: () => void;
  isAdminInviting?: boolean;
  meta?: InviteMemberMeta;
  type?: RoleName | SystemRoleName;
};

const INVITATION_SENT_MESSAGE = 'Your invitations have been sent.';
const INVITATION_WAITING_MESSAGE = 'Your invitations have been sent and are being processed.';

export function InviteMemberModal({
  onClose,
  onSuccessfulSubmit,
  isAdminInviting = false,
  type = SystemRoleName.CommunityMember,
  meta,
}: Props) {
  const [uploading, setUploading] = useState(false);

  const { isMobile } = useResponsive();
  const { showError, showSuccess, showWarning, showMessage, dismiss } = useToast();
  const { isLoading: isRolesLoading } = useUpdateUserRoleAddons();

  const showUploadCSV = !isMobile && isAdminInviting;

  const { inviteUsers, inviteAdmins, inviteBusinessAdmins, inviteBusinessManagers } =
    useUserInvitation({
      refetchQueries: ['SchoolMembersReport', 'MemberStats'],
    });

  const { handle: handleUploadCSV } = useUserInvitationCSV({
    onStart() {
      setUploading(true);
      showMessage(INVITING_CSV_MESSAGE, { id: INVITING_CSV_MESSAGE_KEY });
    },

    async onComplete(parsed) {
      await onSubmit({ members: parsed });
    },
  });

  const onSubmit = async ({ members: formData }: InviteMemberFormValues) => {
    const userData = formData.map(data => ({
      ...data,
      graduatingYearIso: mapGraduatingYear(data.graduatingYear),
    }));

    try {
      if (userData.length === 0) {
        throw new Error('You haven`t provided needed info to invite person. Review it carefully.');
      }

      if (type === SystemRoleName.BusinessAdmin) {
        await inviteBusinessAdmins(userData);
      }

      if (type === SystemRoleName.BusinessManager) {
        if (!meta?.businessId) {
          return;
        }

        await inviteBusinessManagers(userData, meta?.businessId);
      }

      if (type === SystemRoleName.CommunityAdmin) {
        await inviteAdmins(userData);
      }

      if (type === SystemRoleName.CommunityMember) {
        await inviteUsers(userData, isAdminInviting);
      }

      if (userData.length < 10) {
        showSuccess(INVITATION_SENT_MESSAGE);
      } else {
        showWarning(INVITATION_WAITING_MESSAGE);
      }

      onSuccessfulSubmit?.();
      onClose();
    } catch (err) {
      recordError(err);

      if (err instanceof ApolloError) {
        for (const error of err.graphQLErrors) {
          showError(error.message);
        }

        return;
      }

      if (err instanceof Error) {
        showError(err.message);
        return;
      }

      showError(ERROR_GENERIC_MESSAGE);
    } finally {
      dismiss(INVITING_CSV_MESSAGE_KEY);
      setUploading(false);
    }
  };

  return (
    <Form<InviteMemberFormValues> initialValues={initialValues} onSubmit={onSubmit}>
      {form => {
        const isPending = form.isSubmitting || form.isValidating || uploading || isRolesLoading;

        return (
          <Fragment>
            <FormFieldArray name="members">
              {({ remove, push }) => (
                <Fragment>
                  {form.values.members.map((_, index) => (
                    <InviteMemberItem
                      name="members"
                      key={index}
                      index={index}
                      onRemove={() => remove(index)}
                    />
                  ))}

                  <Button
                    startIcon={<Add />}
                    onClick={() => push(createInitialValues())}
                    size="small"
                  >
                    Add another
                  </Button>
                </Fragment>
              )}
            </FormFieldArray>

            <DialogFooter>
              {showUploadCSV && (
                <FileInputButton accept={FILE_TYPE} disabled={isPending} onChange={handleUploadCSV}>
                  Upload Csv
                </FileInputButton>
              )}

              <Button onClick={onClose} disabled={isPending}>
                CANCEL
              </Button>

              <Button
                loading={isPending}
                disabled={isPending}
                disableElevation
                variant="contained"
                type="submit"
                color="primary"
              >
                SEND INVITES
              </Button>
            </DialogFooter>
          </Fragment>
        );
      }}
    </Form>
  );
}
