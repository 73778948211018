import { SchoolMemberFragment, UserInfoFragment } from 'shared/graphql';

import { Affiliation, Header, Title } from './ExplorerUserCard.styles';

interface Props {
  user: UserInfoFragment | SchoolMemberFragment;
}

export function ExplorerUserCardHeader(props: Props) {
  return (
    <Header>
      <Title user={props.user} />

      {typeof props.user.affiliation === 'string' && <Affiliation label={props.user.affiliation} />}
    </Header>
  );
}
