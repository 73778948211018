import { useMemo } from 'react';
import { Box, Typography } from '@mui/material';

import { formatShortDate } from '@jebel/utils';

import { useSearchDateFilterContext } from 'shared/features/search';
import { DateRange } from 'shared/hooks';

interface DashboardChartTemplateTopProps {
  title: string;
}

export function DashboardChartTemplateTop({ title }: DashboardChartTemplateTopProps) {
  const { filter } = useSearchDateFilterContext<DateRange>();

  const from = useMemo(() => formatShortDate(filter.startDate), [filter.startDate]);
  const to = useMemo(() => formatShortDate(filter.endDate), [filter.endDate]);

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="h6">{title}</Typography>

      <div>
        <Typography component="span">{from}</Typography>
        <span> - </span>
        <Typography component="span">{to}</Typography>
      </div>
    </Box>
  );
}
