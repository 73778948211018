import { gql } from '@apollo/client';

import { CAREER_INFO_FRAGMENT } from 'shared/features/search/queries';

import { DISCOUNT_PRESENTATION_FRAGMENT } from './discounts';

export const ORGANIZATION_LOCATION_ITEM_FRAGMENT = gql`
  fragment OrganizationLocationItem on OrganizationLocation {
    id
    locationName
    operationalHours
    contactName
    email
    phoneNumber
    link
    address {
      items {
        id
        state
        zip
        street1
        city
      }
    }
  }
`;

export const ORGANIZATION_FRAGMENT = gql`
  fragment OrganizationItem on Organization {
    id
    homeFeedViews
    description
    industry
    name
    likesCount
    status
    createdAt

    userLikes {
      items {
        id
        user {
          id
        }
        organization {
          id
        }
      }
    }

    logo {
      id
      downloadUrl
      fileId
      filename
    }

    schoolSupporter {
      items {
        id

        school {
          id
        }
      }
    }

    media {
      items {
        downloadUrl
        meta
        filename
        fileId
        id
      }
    }

    advertising {
      id
      supporter {
        id
        isSupporter
      }
    }

    location {
      items {
        ...OrganizationLocationItem
      }
    }

    managers {
      items {
        id
        status
        isAdmin
        user {
          id
          email
          firstName
          lastName
          affiliation
          rolesAddons {
            items {
              id
              name
            }
          }
        }
      }
    }

    owner {
      id
      email
      firstName
      lastName
    }

    jobOffers {
      items {
        id
        description
        position
        createdAt
        createdBy {
          firstName
          lastName
          id
          avatar {
            id
            downloadUrl
          }
        }
        organization {
          id
          name
        }
      }
    }

    discounts {
      items {
        ...DiscountPresentation
      }
    }
  }

  ${DISCOUNT_PRESENTATION_FRAGMENT}
  ${ORGANIZATION_LOCATION_ITEM_FRAGMENT}
`;

export const ORGANIZATION_PROFILE_FRAGMENT = gql`
  fragment OrganizationProfile on Organization {
    id
    name
    industry
    description
    createdAt

    logo {
      id
      downloadUrl
      fileId
      filename
    }

    userLikes {
      count
      items {
        id
        user {
          id
        }
        organization {
          id
        }
      }
    }

    schoolSupporter {
      items {
        id
      }
      count
    }

    media {
      items {
        downloadUrl
        meta
        filename
        fileId
        id
      }
    }

    location {
      items {
        ...OrganizationLocationItem
      }
    }

    owner {
      id
    }

    managers {
      items {
        id
        status
        isAdmin
        user {
          id
          firstName
          lastName
          email
        }
      }
    }

    jobOffers {
      items {
        ...CareerInfo
      }
    }

    discounts(filter: { status: { equals: "active" } }) {
      items {
        ...DiscountPresentation
      }
    }
  }

  ${DISCOUNT_PRESENTATION_FRAGMENT}
  ${CAREER_INFO_FRAGMENT}
  ${ORGANIZATION_LOCATION_ITEM_FRAGMENT}
`;

export const SEND_BUSINESS_MANAGER_INVITES_MUTATION = gql`
  mutation SendBusinessManagerInvites(
    $school: SchoolConfigurationKeyFilter
    $organization: OrganizationKeyFilter!
    $invites: [SendBusinessManagerInviteInput!]!
  ) {
    commit: sendBusinessManagerInvites(
      organization: $organization
      school: $school
      invites: $invites
    ) {
      success
    }
  }
`;
