import { useMemo } from 'react';

import { createFilterBuilder } from '@jebel/utils';

import {
  useTrendingSearchesAddWordMutation,
  useTrendingSearchesUpdateWordCountMutation,
  useTrendingSearchesListQuery,
  TrendingSearchUpdateInput,
  TrendingSearchCreateInput,
  TrendingSearchFilter,
  useTrendingSearchesListLazyQuery,
} from 'shared/graphql';
import { FilterType } from 'shared/features/spreadsheet';
import { DateRange, useSchoolConfiguration } from 'shared/hooks';
import { recordException } from 'shared/utils/record';

export const useTrendingSearchesFilter = ({
  currentFilter,
  filterByCount,
  filterByWord,
  dateRange,
}: {
  currentFilter?: FilterType | null;
  filterByCount: string;
  filterByWord: string;
  dateRange?: DateRange;
}) => {
  const { data: school } = useSchoolConfiguration();

  return useMemo(() => {
    const filter = createFilterBuilder<TrendingSearchFilter>(currentFilter);

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1685
      filter.and({ school: { id: { equals: school.id } } });
    }

    if (filterByCount) {
      filter.and({ searchesCount: { gte: Number(filterByCount) } });
    }

    if (filterByWord) {
      filter.and({ wordOrPhrase: { contains: filterByWord } });
    }

    if (dateRange?.startDate) {
      filter.and({ createdAt: { gte: dateRange.startDate } });
    }

    if (dateRange?.endDate) {
      filter.and({ createdAt: { lte: dateRange.endDate } });
    }

    return filter.build();
  }, [currentFilter, filterByCount, filterByWord, dateRange, school]);
};

/** @deprecated This is a bad practice, please DO NOT use. */
export const useTrendingSearchesList = () => {
  const { data, loading, ...rest } = useTrendingSearchesListQuery({
    fetchPolicy: 'cache-first',
  });

  return {
    trendingSearchesList: data?.trendingSearchesList?.items ?? [],
    loading: loading && !data,
    ...rest,
  };
};

export function useTrendingSearchesUpdate() {
  const [updateSearchingWord] = useTrendingSearchesUpdateWordCountMutation();

  const onUpdateSearchingWord = async (data: TrendingSearchUpdateInput) => {
    if (!data?.id) {
      return;
    }

    await updateSearchingWord({ variables: { data } });
  };

  return {
    onUpdateSearchingWord,
  };
}

export function useTrendingSearchesCreate() {
  const { data: school } = useSchoolConfiguration();
  const { onUpdateSearchingWord } = useTrendingSearchesUpdate();

  const [findManyTrendingSearches] = useTrendingSearchesListLazyQuery();
  const [addSearchingWord] = useTrendingSearchesAddWordMutation();

  const onAddSearchingWord = async (data: TrendingSearchCreateInput) => {
    if (!data?.wordOrPhrase) {
      return;
    }

    try {
      const trendingSearchesResponse = await findManyTrendingSearches({
        variables: {
          filter: {
            wordOrPhrase: { equals: data.wordOrPhrase },
            // Relate to the school where the user searched.
            // https://github.com/jebelapp/jebel/issues/1697
            school: { id: { equals: school?.id } },
          },
        },
      });

      const [trendingSearch] = trendingSearchesResponse.data?.trendingSearchesList.items ?? [];

      if (trendingSearch?.id) {
        const previousCount = trendingSearch?.searchesCount ?? 0;
        const newCount = data.searchesCount ?? 1;

        await onUpdateSearchingWord({
          id: trendingSearch.id,
          searchesCount: previousCount + newCount,
        });

        return;
      }

      await addSearchingWord({
        variables: {
          data: {
            searchesCount: 1,
            wordOrPhrase: data?.wordOrPhrase,
            // Relate to the school where the user searched.
            // https://github.com/jebelapp/jebel/issues/1697
            school: {
              connect: { id: school?.id },
            },
          },
        },
      });
    } catch (err) {
      recordException(err);
    }
  };

  return {
    onAddSearchingWord,
  };
}
