import { OperationVariables } from '@apollo/client';

import { WriteCSVOptions, downloadCSV, writeCSV } from '@jebel/utils';

import { recordError } from 'shared/utils/record';

import {
  UseDownloadLazyQueryDocument,
  UseDownloadLazyQueryOptions,
  useDownloadLazyQuery,
} from './useDownloadLazyQuery';

export type UseDownloadLazyQueryCSVOptions<
  D,
  V extends OperationVariables,
  T,
> = UseDownloadLazyQueryOptions<D, V, T> & WriteCSVOptions<T>;

const FALLBACK_FILENAME = 'jebel.csv';

/**
 * Custom hook to execute a lazy query and download the result as a CSV file.
 *
 * @template D - The type of the data returned by the query.
 * @template V - The type of the variables used in the query.
 * @template T - The type of the data to be written to the CSV.
 *
 * @param {UseDownloadLazyQueryDocument<D, V>} query - The GraphQL query document to be executed.
 * @param {UseDownloadLazyQueryCSVOptions<D, V, T>} [options] - Optional configuration for the query and CSV generation.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useDownloadLazyQueryCSV<D = any, V extends OperationVariables = any, T = unknown>(
  query: UseDownloadLazyQueryDocument<D, V>,
  options?: UseDownloadLazyQueryCSVOptions<D, V, T>,
) {
  const [generate, params] = useDownloadLazyQuery(query, options);

  const execute = async () => {
    try {
      const response = await generate();
      const csv = writeCSV(response as T[], options);

      downloadCSV(csv, options?.filename ?? FALLBACK_FILENAME);
    } catch (err) {
      recordError(err);
    }
  };

  return [execute, params] as const;
}
