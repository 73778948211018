import { gql } from '@apollo/client';

export const REQUEST_COMMUNITY_ACCESS_MUTATION = gql`
  mutation RequestCommunityAccess(
    $email: String!
    $firstname: String!
    $lastname: String!
    $affiliation: String!
    $affiliationDescription: String
    $graduatingYear: String
    $phoneNumber: String
    $school: SchoolConfigurationKeyFilter!
  ) {
    commit: requestCommunityAccess(
      email: $email
      firstname: $firstname
      lastname: $lastname
      affiliation: $affiliation
      affiliationDescription: $affiliationDescription
      graduatingYear: $graduatingYear
      phoneNumber: $phoneNumber
      school: $school
    ) {
      success
    }
  }
`;
