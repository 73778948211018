import { useMemo, useState } from 'react';
import { Theme, css } from '@emotion/react';
import { Box } from '@material-ui/core';

import { formatTableDate, parseDate } from '@jebel/utils';

import { Typography } from 'shared/components/ui';
import { useSpreadsheetContext } from 'shared/features/spreadsheet';
import { useSearchContext, useSearchDateFilterContext } from 'shared/features/search';
import { SELECT_OPTION_ALL } from 'shared/constants';
import { DateRange } from 'shared/hooks';

import { REPORT_TYPE, REVENUE_CATEGORY } from '../constants';
import { LocalFilterType } from '../types';
import { ReportsLeftSidebar } from './ReportsLeftSidebar';
import { RevenueReportsSpreadsheet } from './RevenueReportsSpreadsheet';
import { TrendingSearchesReportsSpreadsheet } from './TrendingSearchesReportsSpreadsheet';
import { ReportsPlug } from './ReportsPlug';
import { ContentReportsSpreadsheet } from './ContentReportsSpreadsheet';

export function ReportsContent() {
  const { setPage, reset: resetSpreadsheet } = useSpreadsheetContext();
  const { setSearchQuery } = useSearchContext();
  const { filter: dateRange } = useSearchDateFilterContext<DateRange>();

  const [reportsFilter, setReportsFilter] = useState<LocalFilterType>({
    reportType: REPORT_TYPE.contentEngagementReport,
    postCategory: SELECT_OPTION_ALL,
    withReactions: false,
    withComments: false,
    revenueCategory: REVENUE_CATEGORY.all,
    trendingWordsCount: '',
    trendingWord: '',
    wasReportRan: false,
  });

  const textToolbarHeader = useMemo(() => {
    const type = reportsFilter.reportType;

    const startDate = parseDate(dateRange?.startDate);
    const endDate = parseDate(dateRange?.endDate);

    if (startDate.isValid && endDate.isValid) {
      const startDateTable = formatTableDate(startDate);
      const endDateTable = formatTableDate(endDate);

      return `${type}: ${startDateTable} - ${endDateTable}`;
    }

    if (startDate.isValid) {
      const startDateTable = formatTableDate(startDate);

      return `${type}: ${startDateTable} - *`;
    }

    if (endDate.isValid) {
      const endDateTable = formatTableDate(endDate);

      return `${type}: * - ${endDateTable}`;
    }

    return `${type}`;
  }, [reportsFilter]);

  const resetReport = () => {
    setSearchQuery('');
    resetSpreadsheet();
  };

  const changeReport = (configuration: LocalFilterType) => {
    setReportsFilter(configuration);
    resetReport();
  };

  return (
    <Box display="flex" flexDirection="column" flexGrow={1}>
      <Box display="flex" justifyContent="start" alignItems="center" ml={3} my={3}>
        <Typography variant="subtitle2" color="primary">
          Reports
        </Typography>
      </Box>

      <div css={contentCSS}>
        <ReportsLeftSidebar setReportsFilter={changeReport} setPage={setPage} />

        {!reportsFilter.wasReportRan &&
          reportsFilter.reportType !== REPORT_TYPE.contentEngagementReport && <ReportsPlug />}

        {reportsFilter.reportType === REPORT_TYPE.contentEngagementReport && (
          <ContentReportsSpreadsheet
            toolbarHeader={<ToolbarHeader title={textToolbarHeader} />}
            textToolbarHeader={textToolbarHeader}
            selectedCategory={reportsFilter.postCategory}
          />
        )}

        {reportsFilter.reportType === REPORT_TYPE.revenueReport && reportsFilter.wasReportRan && (
          <RevenueReportsSpreadsheet
            category={reportsFilter.revenueCategory}
            toolbarHeader={<ToolbarHeader title={textToolbarHeader} />}
            textToolbarHeader={textToolbarHeader}
          />
        )}

        {reportsFilter.reportType === REPORT_TYPE.trendingSearchesReport &&
          reportsFilter.wasReportRan && (
            <TrendingSearchesReportsSpreadsheet
              filterByCount={reportsFilter?.trendingWordsCount}
              filterByWord={reportsFilter?.trendingWord}
              toolbarHeader={<ToolbarHeader title={textToolbarHeader} />}
              textToolbarHeader={textToolbarHeader}
            />
          )}
      </div>
    </Box>
  );
}

function ToolbarHeader(props: { title: string }) {
  const date = formatTableDate();

  return (
    <Box ml={3} display="flex" justifyContent="space-between" alignItems="center">
      <Typography variant="subtitle4">{props.title}</Typography>

      <Typography color="textSecondary" css={runReportCss}>
        RUN DATE: {date}
      </Typography>
    </Box>
  );
}

const runReportCss = (theme: Theme) => css`
  font-weight: 500;
  font-size: ${theme.typography.fontSize}px;
`;

const contentCSS = (theme: Theme) => css`
  display: grid;
  grid-template-columns: 22rem 1fr;
  gap: 2rem;

  & > * {
    min-width: 0;
  }

  ${theme.breakpoints.down('md')} {
    grid-template-columns: 1fr;
  }
`;
