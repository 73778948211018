import { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { groupBy } from 'ramda';

import { USER_STATUS_TITLES, UserStatus } from '@jebel/constants';

import { MinimalUserFragment } from 'shared/graphql';
import { ExplorerUserCard } from 'features/explorer/components';
import { FeedSkeleton } from 'shared/components/ui';
import { useGraduatedUsersListPerYear } from 'features/scorecard/hooks';

interface ClassViewDedailsModalProps {
  year: string;
}

export function ClassViewDedailsModal({ year }: ClassViewDedailsModalProps) {
  const { data: users, loading } = useGraduatedUsersListPerYear({
    year,
    // Sort users alphabetically by last name.
    // https://github.com/jebelapp/jebel/issues/1601#issuecomment-2471474935
    variables: { sort: [{ lastName: 'DESC' }] },
  });

  const usersByStatus = useMemo(() => {
    const usersByStatus = groupBy(user => user.status as UserStatus, users);

    const statuses: Array<{ status: UserStatus; users: MinimalUserFragment[] }> = [];

    for (const status of Object.keys(usersByStatus)) {
      statuses.push({
        status: status as UserStatus,
        users: usersByStatus[status] || [],
      });
    }

    return statuses;
  }, [users]);

  if (loading) {
    return <FeedSkeleton count={3} />;
  }

  return (
    <Box display="flex" flexDirection="column" gridGap="1rem">
      {usersByStatus.map(group => (
        <Box display="grid" gridGap="1rem">
          <Typography color="secondary">
            {USER_STATUS_TITLES[group.status]} Alumni ({group.users.length})
          </Typography>

          <Box>
            {group.users.map(user => (
              <ExplorerUserCard key={user.id} data={user} withDivider withMessage />
            ))}
          </Box>
        </Box>
      ))}

      {usersByStatus.length === 0 && (
        <Typography color="textSecondary">No users found for this class year</Typography>
      )}
    </Box>
  );
}
