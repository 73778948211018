import { css } from '@emotion/react';

import { DISCOUNT_CATEGORIES } from 'shared/constants/discounts';
import { HeadlinesType, SpreadsheetFiltersType } from 'shared/features/spreadsheet';
import { DiscountSort, SortOrder } from 'shared/graphql';

export const COLUMBUS_HIGH_SCHOOL = 'Columbus High School';

export const DiscountsSpreadsheetHeader: HeadlinesType<DiscountSort> = [
  {
    name: 'name',
    label: 'NAME',
    id: 'title',
    sortPath: (order: SortOrder) => ({ title: order }),
    css: () =>
      css`
        width: 22.9%;
      `,
  },
  {
    name: 'category',
    label: 'CATEGORY',
    id: 'category',
    sortPath: (order: SortOrder) => ({ category: order }),
    css: () =>
      css`
        width: 17.04%;
      `,
  },
  {
    name: 'createdBy',
    label: 'CREATED BY',
    id: 'createdBy',
    sortPath: (order: SortOrder) => ({ createdBy: { firstName: order } }),
    css: () =>
      css`
        width: 16.67%;
      `,
  },

  {
    name: 'expiredDate',
    label: 'EXPIRES ON',
    id: 'expirationDate',
    sortPath: (order: SortOrder) => ({ expirationDate: order }),
    css: () =>
      css`
        width: 12.35%;
      `,
  },
  {
    name: 'redeemCount',
    label: 'REDEEMED',
    id: 'usersRedeemedCount',
    sortPath: (order: SortOrder) => ({ usersRedeemedCount: order }),
    css: () =>
      css`
        width: 8.64%;
      `,
  },
  {
    name: 'createdAt',
    label: 'CREATED ON',
    id: 'createdAt',
    sortPath: (order: SortOrder) => ({ createdAt: order }),
    css: () =>
      css`
        width: 16.67%;
      `,
  },
];

export const DiscountsSpreadsheetFilters: SpreadsheetFiltersType = [
  {
    type: 'select',
    variants: DISCOUNT_CATEGORIES.map(({ value }) => ({ value })),
    label: 'Category',
    name: 'category',

    filterPath(value: string) {
      if (!value) {
        return {};
      }

      return {
        category: { equals: DISCOUNT_CATEGORIES.find(discount => discount.value === value)?.key },
      };
    },
  },
  {
    type: 'input',
    name: 'zip',
    label: 'ZIP Code',

    filterPath(value: string) {
      if (!value) {
        return {};
      }

      return {
        organization: {
          location: { some: { address: { some: { zip: { equals: value } } } } },
        },
      };
    },
  },
];

export enum DiscountReportHeaders {
  name = 'Name',
  category = 'Category',
  createdBy = 'Created By',
  expiresOn = 'Expires On',
  redeemed = 'Redeemed',
  createdOn = 'Created on',
  status = 'Status',
}
