import { useMemo } from 'react';

import { DashboardFilter, useDashboardsMembersListQuery } from 'shared/graphql';

import { createFilterBuilder, ParseDateValue } from '@jebel/utils';
import { useSchoolConfiguration } from 'shared/hooks';

interface Params {
  /**
   * Start date for the filter.
   */
  startDate?: ParseDateValue;

  /**
   * End date for the filter.
   */
  endDate?: ParseDateValue;
}

export function useDashboardMembersStats(params: Params = {}) {
  const { data: school } = useSchoolConfiguration();

  const filter = useMemo(() => {
    const filter = createFilterBuilder<DashboardFilter>();

    if (school) {
      // Filter by the current school.
      // https://github.com/jebelapp/jebel/issues/1675
      filter.and({ school: { id: { equals: school.id } } });
    }

    if (params.startDate) {
      filter.and({ createdAt: { gte: params.startDate } });
    }

    if (params.endDate) {
      filter.and({ createdAt: { lte: params.endDate } });
    }

    return filter.build();
  }, [params, school]);

  const { data: response, loading } = useDashboardsMembersListQuery({
    skip: !school,
    variables: { filter },
  });

  const data = response?.dashboardsList.items ?? [];

  return {
    data,
    loading,
  };
}
