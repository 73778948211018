import { useSchoolContext } from 'providers';
import { ascend } from 'ramda';

import { SchoolConfigurationExternalLink, SchoolConfigurationLayout } from 'shared/graphql';

/**
 * Hook for fetching school configuration.
 */
export function useSchoolConfiguration() {
  const { selectedSchool, loading, selectSchool } = useSchoolContext();

  return {
    data: selectedSchool ?? undefined,
    /** @deprecated Use `data` instead. */
    configuration: selectedSchool ?? undefined,

    /**
     * Allow to switch the selected school.
     * @param id The `id` of the school to select.
     */
    selectSchool,

    loading,
  };
}

export const sortLabels = (items: SchoolConfigurationLayout[]): SchoolConfigurationLayout[] => {
  const copiedItems = [...items];
  copiedItems.sort((a, b) => (a.sidebarOrderNumber ?? 0) - (b.sidebarOrderNumber ?? 0));
  return copiedItems;
};

export const sortMobileLabels = (
  items: SchoolConfigurationLayout[],
): SchoolConfigurationLayout[] => {
  const pages = [...items];

  return pages
    .filter(page => typeof page?.navbarOrderNumber === 'number')
    .sort(ascend(page => page?.navbarOrderNumber as number));
};

export const sortExternalLinks = (
  items: SchoolConfigurationExternalLink[],
): SchoolConfigurationExternalLink[] => {
  const copiedItems = [...items];
  copiedItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));
  return copiedItems;
};
